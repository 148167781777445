import React, { useContext, useEffect, useState } from "react";
import { Acknowledgement, AdditionalDetailsBody, AdditionalDetailsContainer, BusinessText, BusinessTitle, ErrorText, FormsFlex, OnboardingButtonContainer, PageHeaderWrapper } from "../styles";
import FormSection from "../components/FormSection";
import { Controller, useForm } from "react-hook-form";
import { AdditionalInfoTypes } from "../Types";
import CustomRadio from "../../../components/common/RadioInput";
import { Button } from "../../../components/common/Button";
import CustomSelectTwo from "../../../components/common/CustomSelect/CustomSelectTwo";
import FormField from "../../../components/FormField/FormField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addAdditionalInformation, getParticularStep } from "../redux/actions";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { noOfTxnsOptions, polarResponse, valueOfTxnsOptions } from "../utils/constants";
import Loader from "../../../components/common/Loader";
import { useWindowSize } from "@reach/window-size";

type Props = {
  handleNext: () => void;
  handlePrevious: () => void;
};

const AdditionalDetails = ({ handleNext, handlePrevious }: Props) => {

  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const { businessId } = useAppSelector((state) => state.users)
  const { loading } = useAppSelector((state) => state.onboarding || {});
  const { onShowAlert: onShowErrorAlert } =
    useContext(NetworkErrorAlertContext) || {};

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<AdditionalInfoTypes>({ 
    mode: 'onChange',
    defaultValues: {
      averageMonthlyTransactionNumber: '',
      averageMonthlyTransactionValue: '',
      fundSource: '',
      companyConvictions: '',
      moneyLaunderingAlert: '',
      pepPolicy: '',
    }
  });

  const watchAllFields = watch();

  const handleRadioChange = (name, value) => {
    setValue(name, watchAllFields[name] === value ? "" : value, { shouldValidate: true });
  };

  const handleAdditionalDetailsSubmit = async (data: AdditionalInfoTypes) => {
    const payload = {
      businessId,
      id: data?.id,
      ...data,
    }
    const action = await dispatch(addAdditionalInformation(payload))
    if (addAdditionalInformation.fulfilled.match(action)) {
      handleNext()
    } else if (addAdditionalInformation.rejected.match(action)) {
      onShowErrorAlert('', String(action.payload))
    }
  }

  const getSavedAdditionalDetails = async () => {
    const stepNumber = 6
    const action = await dispatch(getParticularStep({businessId, stepNumber}))

    if(getParticularStep.fulfilled.match(action)) {
        reset({
          averageMonthlyTransactionNumber: action?.payload?.averageMonthlyTransactionNumber,
          averageMonthlyTransactionValue: action?.payload?.averageMonthlyTransactionValue,
          fundSource: action?.payload?.fundSource,
          companyConvictions: action?.payload?.companyConvictions,
          moneyLaunderingAlert: action?.payload?.moneyLaunderingAlert,
          pepPolicy: action?.payload?.pepPolicy,
          id: action?.payload?.id,
        })
      } else if (getParticularStep.rejected.match(action)) {
        console.error('We could not retrieve previously saved data.');
      }
  
  }
  
  useEffect(() => {
    getSavedAdditionalDetails()
  }, [])

  return (
    <AdditionalDetailsContainer>
      <Loader isLoading={loading} />
      <PageHeaderWrapper>
        <BusinessTitle>Additional details</BusinessTitle>
        <BusinessText>A little bit more details on your business to help us know you better</BusinessText>
      </PageHeaderWrapper>

      <AdditionalDetailsBody>
        <FormSection>
          <Controller
            name="averageMonthlyTransactionNumber"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CustomSelectTwo
                animatedLabel="Average monthly number of transactions"
                $width="100%"
                lightBorder={true}
                options={noOfTxnsOptions}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {errors.averageMonthlyTransactionNumber?.type === "required" && (
            <ErrorText>
              <span>Field is required</span>
            </ErrorText>
          )}
        </FormSection>
        <FormSection>
          <Controller
            name="averageMonthlyTransactionValue"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CustomSelectTwo
                animatedLabel="Average monthly value of transactions in USD"
                $width="100%"
                lightBorder={true}
                options={valueOfTxnsOptions}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {errors.averageMonthlyTransactionValue?.type === "required" && (
            <ErrorText>
              <span>Field is required</span>
            </ErrorText>
          )}
        </FormSection>
        <FormSection>
          <FormField
            name="fundSource"
            control={control}
            label="Main source of funds"
            required
            errors={errors}
          />
        </FormSection>

        <FormSection>
          <p>Have you ever been the subject of any convictions or prosecutions, or is the subject of any pending investigations, in relation to bribery or corruption or money laundering?</p>
          <Controller
            name="companyConvictions"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormsFlex marginBottom={false}>
                {polarResponse.map((item, index) => (
                  <CustomRadio
                    key={index}
                    label={item.label}
                    value={item.value}
                    onClick={() => handleRadioChange('companyConvictions', item.value)}
                    selected={field.value === item.value}
                    border="#E9E9E9"
                  />
                ))}
              </FormsFlex>
            )}
          />
          {errors.companyConvictions?.type === "required" && (
            <ErrorText>
              <span>Choose an option</span>
            </ErrorText>
          )}

        </FormSection>

        <FormSection>
          <p>Does your company agree to alert Klasha immediately should there be any suspicion of money laundering in relation to its business transactions?</p>
          <Controller
            name="moneyLaunderingAlert"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormsFlex marginBottom={false}>
                {polarResponse.map((item, index) => (
                  <CustomRadio
                    key={index}
                    label={item.label}
                    value={item.value}
                    onClick={() => handleRadioChange('moneyLaunderingAlert', item.value)}
                    selected={field.value === item.value}
                    border="#E9E9E9"
                  />
                ))}
              </FormsFlex>
            )}
          />
          {errors.moneyLaunderingAlert?.type === "required" && (
            <ErrorText>
              <span>Choose an option</span>
            </ErrorText>
          )}
        </FormSection>
      </AdditionalDetailsBody>

      <div>
        <Acknowledgement align="start" marginTop="-16px">
          <Controller
            name="pepPolicy"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <input
                type="checkbox"
                checked={field.value === 'yes'}
                onChange={(e) => field.onChange(e.target.value ? 'yes' : 'no')}
              />
            )}
          />
          <span>The company confirms that none of its directors/shareholders fall within the definition of 'politically exposed persons (PEPs)', being natural persons who are or have been entrusted with prominent public functions, including their immediate family members or persons known to be close associates of such persons.</span>
        </Acknowledgement>
        {errors.pepPolicy?.type === "required" && (
          <ErrorText>
            <span>Please confirm this</span>
          </ErrorText>
        )}
      </div>

      <OnboardingButtonContainer>
        <Button
          label="Back"
          theme="alternate"
          height="48px"
          fontSize="16px"
          width="150px"
          onClick={handlePrevious}
        />
        <Button
          onClick={handleSubmit(handleAdditionalDetailsSubmit)}
          label="I agree"
          width={width < 1150 ? '' : '494px' }
          height="48px"
          fontSize="16px"
          disabled={!isValid || loading}
        />
      </OnboardingButtonContainer>
    </AdditionalDetailsContainer>
  );
};

export default AdditionalDetails;
