import React, { useContext, useMemo, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Wrapper, ModalTitle, ModalSubtitle, HeaderWrapper, BodyWrapper, ButtonContainer } from "./style";
import { Button } from "../Button";
import CustomRadio from "../RadioInput";
import CustomDatePicker from "../CustomDateRangePicker/CustomDatePicker";
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import {  fileFormatOptions } from './constants';
import { useStatementExport } from "../../../utils/fetchStatementsForExport";
import { selectUserRoleAndPermissions } from "../../../pages/Settings/Team/redux/slice";

type Props = {
    exportByFilterFunction: (filterParams: any) => void;
    location: string;
    modalTitle: string;
    modalSubTitle: string;
    id?: number;
    exportFileName?: string,
}

const ExportByFilterModal: React.FC<Props> = ({exportByFilterFunction, location, modalTitle, modalSubTitle, ...props}) => {
    const dispatch = useAppDispatch()
    const abortControllerRef = useRef(null);
    const {onShowAlert: onShowErrorAlert} = useContext(NetworkErrorAlertContext);
    const {role, permissions} = useAppSelector(
        selectUserRoleAndPermissions,
    );

    const exportLocationStringCheck = {
        collections: "EXPORT_COLLECTION_STATEMENT",
        wallet:  "EXPORT_WALLET_STATEMENT",
    }

    const { noPermissionToExport } = useMemo(() => {
        const hasPermissions = permissions?.[location]?.includes(exportLocationStringCheck[location])
        return {
            noPermissionToExport: !hasPermissions || role !== 'OWNER'
        }
    }, [permissions, location, exportLocationStringCheck, role])

    const { control, handleSubmit, watch, setValue, formState: { isValid }, reset } = useForm({
        mode: "onChange",
        defaultValues: {
            startDate: "",
            endDate: "",
            transactionType: "",
            fileFormat: ""
        }
    });

    const watchAllFields = watch();

    const handleRadioChange = (name, value) => {
        setValue(name, watchAllFields[name] === value ? "" : value, { shouldValidate: true });
    };

    const threeMonthsAgo = dayjs().subtract(3, 'month').startOf('day')
    const disableDatesOutsideRange = (current: dayjs.Dayjs) => {
        const today = dayjs().endOf('day')

        return current < threeMonthsAgo || current > today
    }

    const onSubmit = async (data) => {
        if (data.startDate > data.endDate) {
            onShowErrorAlert('', 'The Start Date cannot be later than the End Date');
            return
        }
        const allPayloads = {
            data: data,
            exportByFilterFunction: exportByFilterFunction,
            abortControllerRef: abortControllerRef,
            dispatch: dispatch,
            id: props.id,
            exportFileName: props.exportFileName,
            location: location
        }
       
        try {
            if (noPermissionToExport) {
                onShowErrorAlert("", "Access is denied");
                return
            } else {
                const result = await useStatementExport(allPayloads)
                if (result.success) {
                    reset({
                        startDate: "",
                        endDate: "",
                        transactionType: "",
                        fileFormat: ""
                    });
                } else {
                    onShowErrorAlert('', `Export failed: ${result.error.message}`)
                }
            }
        } catch (error) {
            onShowErrorAlert('', 'Unexpected error, please try again');
        }
    };

    return (
        <Wrapper>
            <HeaderWrapper>
                <ModalTitle>
                    {modalTitle}
                </ModalTitle>
                <ModalSubtitle>
                    {modalSubTitle}
                </ModalSubtitle>
            </HeaderWrapper>

            <form onSubmit={handleSubmit(onSubmit)}>
                <BodyWrapper>
                    <Controller
                        name="startDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <CustomDatePicker
                                value={field.value}
                                onChange={(date) => field.onChange(date)}
                                placeholder=""
                                animatedLabel="Start date"
                                lightBorder={true}
                                disabledDate={disableDatesOutsideRange}
                                defaultPickerValue={threeMonthsAgo}
                            />
                        )}
                    />
                    <Controller
                        name="endDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <CustomDatePicker
                                value={field.value}
                                onChange={(date) => field.onChange(date)}
                                placeholder=""
                                animatedLabel="End date"
                                lightBorder={true}
                                disabledDate={disableDatesOutsideRange}
                            />
                        )}
                    />

                    <section>
                        <h2>Preferred file format</h2>
                        <div>
                            <Controller
                                name="fileFormat"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                <>
                                    {fileFormatOptions.map((option) => (
                                        <CustomRadio
                                            key={option.value}
                                            label={option.label}
                                            value={option.value}
                                            onClick={() => handleRadioChange("fileFormat", option.value)}
                                            selected={field.value === option.value}
                                        />
                                    ))}
                                </>
                                )}
                            />
                        </div>
                    </section>
                </BodyWrapper>

                <ButtonContainer>
                    <Button
                        label="Submit request"
                        type="submit"
                        disabled={!isValid}
                        height="40px"
                        fontSize="12px"
                    />
                </ButtonContainer>
            </form>
        </Wrapper>
    );
};

export default ExportByFilterModal;