import { createAsyncThunk } from "@reduxjs/toolkit";
import { formatMerchantAllTransactions } from "../pages/Balances/utils";
import { formatWalletStatementExport } from "../pages/Wallets/utils";
import exportFromJSON from "export-from-json";
import { formatDate } from "./dateUtils";
import { merchantCollectionAccountStatement } from "../pages/Balances/settlementsSlice";
import { getWalletStatementForExport } from "../pages/Wallets/redux/slice";

type FetchParams = {
  id: number | string;
  startDate: string;
  endDate: string;
  pageNumber: number;
};

type TransactionResultsType = {
  transactions: any[];
};

export async function useStatementExport({
  data,
  exportByFilterFunction,
  abortControllerRef,
  dispatch,
  id,
  exportFileName,
  location,
}) {
  const exportResult = {
    success: false,
    error: null,
  };

  const DISPATCH_HANDLERS = {
    collections: merchantCollectionAccountStatement,
    wallet: getWalletStatementForExport,
  };

  try {
    const formattedData = {
      ...data,
      startDate: formatDate(data?.startDate) ?? "",
      endDate: formatDate(data?.endDate) ?? "",
    };

    abortControllerRef.current = new AbortController();

    const dispatchOfInterest = DISPATCH_HANDLERS[location];
    const statement = await dispatch(
      dispatchOfInterest({
        id: id,
        startDate: formattedData.startDate,
        endDate: formattedData.endDate,
      }),
    ).unwrap();

    await exportByFilterFunction({
      ...formattedData,
      statement,
      exportFileName,
    });

    exportResult.success = true;
    return exportResult;
  } catch (error) {
    exportResult.error = {
      message: error?.message,
      code: error?.code,
    };
    return exportResult;
  } finally {
    abortControllerRef.current = null;
  }
}

export function fetchStatementsForExport(
  fetchFunction: (params: FetchParams) => Promise<{
    data: { transactions: TransactionResultsType[]; nextPage: number | null };
  }>,
  actionType: string,
) {
  return createAsyncThunk(actionType, async (params: FetchParams) => {
    let allTransactions: TransactionResultsType[] = [];
    let pageNumber = 1;
    let nextPage: number | null = null;

    do {
      const response = await fetchFunction({
        ...params,
        pageNumber,
      });

      allTransactions = allTransactions.concat(response.data.transactions);
      nextPage = response.data.nextPage;
      pageNumber = nextPage || 0;
    } while (nextPage);

    return allTransactions;
  });
}

export const formatAndNameStatementForExport = async (payload, location) => {
  const FORMAT_HANDLERS = {
    collections: formatMerchantAllTransactions,
    wallet: formatWalletStatementExport,
  };
  const format = FORMAT_HANDLERS[location];
  const data = await format(payload.statement);
  const fileName = `${payload.exportFileName}_statement_${payload.startDate}_to_${payload.endDate}`;
  const exportType =
    payload.fileFormat === "csvFile"
      ? exportFromJSON.types.csv
      : exportFromJSON.types.xls;
  exportFromJSON({
    data,
    fileName,
    exportType,
  });
};
