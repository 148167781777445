import posthog from "posthog-js";

type EventProperties = {
  page_viewed: { path: string };
  Payout: {
    amount: number;
    currency: string;
    payoutMethod: string;
    status: "success" | "failed";
    errorMessage?: string;
  };
  payout_otp: {
    status: "success" | "failure";
    failure_reason?: string;
    currency: string;
  };
  invite_team_form_submitted: {
    status: "success" | "failure";
    submitted_data?:
      | {
          first_name: string;
          last_name: string;
          email: string;
          phone_number: string;
          role: string;
        }
      | string;
    error_message?: string;
  };
};

type EventName = keyof EventProperties;

export class EventFactory {
  private static instance: EventFactory;

  private constructor() {
    if (typeof window !== "undefined") {
      this.initPageTracking();
    }
  }

  public static getInstance(): EventFactory {
    if (!EventFactory.instance) {
      EventFactory.instance = new EventFactory();
    }
    return EventFactory.instance;
  }

  public captureEvent<K extends EventName>(
    eventName: K,
    properties: EventProperties[K],
  ): void {
    posthog.capture(eventName, properties);
  }

  public trackPayout(
    amount: number,
    currency: string,
    payoutMethod: string,
    status: "success" | "failed",
    errorMessage?: string,
  ): void {
    this.captureEvent("Payout", {
      amount,
      currency,
      payoutMethod,
      status,
      errorMessage,
    });
  }

  private initPageTracking(): void {
    window.addEventListener("popstate", this.trackPageView);
    this.trackPageView();
  }

  public trackPageView = (): void => {
    const pathname = window.location.pathname;
    this.captureEvent("page_viewed", { path: pathname });
  };
}

export default EventFactory;
