import TransactionStatus from "../../components/transactions/components/TransctionStatus";
import { countries } from "countries-list";
import dayjs from "dayjs";
import {
  formatCurrencyAmount,
} from "../../utils/currency";

export const CURRENCIES = {
  NGN: { name: "NG Naira", symbol: "&#8358;" },
  USD: { name: "United States Dollar", symbol: "$" },
  ZAR: { name: "South African Rand", symbol: "R" },
  KES: { name: "Kenyan Shillings", symbol: "KSh" },
  ZMW: { name: "Zambian Kwacha", symbol: "ZK" },
  TZS: { name: "Tanzanian Shillings", symbol: "TSh" }
};

export const COLLECTION_CURRENCIES = {
  KES: { name: "Kenyan Shilling", symbol: "KSh" },
  NGN: { name: "Nigerian Naira", symbol: "₦" },
  TZS: { name: "Tanzanian Shilling", symbol: "TSh" },
  UGX: { name: "Ugandan Shilling", symbol: "USh" },
  USD: { name: "United States Dollar", symbol: "$" },
  ZAR: { name: "South African Rand", symbol: "R" },
  ZMW: { name: "Zambian Kwacha", symbol: "ZK" },
};

export type ProgressStatus = "idle" | "progress" | "done" | "failed";

export type Account = {
  accountName: string;
  bankName: string;
  sortCode: string;
  accountNumber: string;
  accountType: string;
  currency: "USD" | "NGN";
};

export type ALLOWED_CURRENCIES = "USD" | "NGN" | "ZAR" | "KES";

export function formatValue(value) {
  const formattedValue = value
    ?.replace(/_/g, " ")
    ?.replace(/([a-z])([A-Z])/g, "$1 $2");

  return (
    formattedValue?.charAt(0)?.toUpperCase() +
    formattedValue?.slice(1)?.toLowerCase()
  );
}

export const getAvailableCurrencies = (
  countryName,
  country,
  setSelectedCurrency,
) => {
  const selectedCountryData = country.find(
    (country) => country.destinationCountry === countryName,
  );
  if (selectedCountryData) {
    setSelectedCurrency(selectedCountryData.availableCurrency);
  } else {
    setSelectedCurrency([]);
  }
};

export const nextVariants = {
  hidden: {
    x: "10vw",
  },
  visible: {
    x: 0,
  },
  transition: {
    type: "spring",
    stiffness: 200,
  },
};

export const formatStatus = (data: string) => {
  if (typeof data === "string" && data === "pending") {
    return "In progress";
  } else {
    return data;
  }
};

export const formatBool = (data) => {
  if (data === true) {
    return "successful";
  } else if (data === false) {
    return "pending";
  } else {
    return "";
  }
};

export const removeLastCharacter = (arg: string) => {
  if (typeof arg === "string" && arg?.length > 0) {
    return arg.slice(0, -1);
  }
  return arg;
};

export const USDTableColumns = [
  {
    title: "Beneficiary name",
    dataIndex: "beneficiary",
    key: "beneficiary",
  },
  {
    title: "Account number",
    dataIndex: "account",
    key: "account",
  },
  {
    title: "Transaction type",
    dataIndex: "transaction_type",
    key: "transaction_type",
  },
  {
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
  },
  {
    title: "Destination amount",
    dataIndex: "destinationAmount",
    key: "destinationAmount",
  },
  {
    title: "Source amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => <TransactionStatus text={text} />,
  },
];

export const tableColumns = [
  {
    title: "Beneficiary name",
    dataIndex: "accountName",
    key: "accountName",
  },
  {
    title: "Account number",
    dataIndex: "accountNumber",
    key: "accountNumber",
  },

  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Fee",
    dataIndex: "fee",
    key: "fee",
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => <TransactionStatus text={text} />,
  },
];

export const payIntableColumns = [
  {
    title: "Total amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Transaction type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
  },
  {
    title: "Transaction ID",
    dataIndex: "transId",
    key: "transId",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Narration",
    dataIndex: "narration",
    key: "narration",
  },
  {
    title: "Fee",
    dataIndex: "fee",
    key: "fee",
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => <TransactionStatus text={text} />,
  },
];

export function capitalizeName(name) {
  if (typeof name !== "string" || name.length === 0) {
    return "";
  }

  return name.charAt(0).toUpperCase() + name.slice(1);
}

export const extractedString = (original) => {
  if (original === "Funding wallet from settlement balance") {
    return "Funding from settlement";
  }
  return original;
};

export const formatWalletStatementExport = async (data) => {
  return data.map((item) => ({
      "Transaction Type": item.transactionType || 'N/A',
      "Transaction Status": item.transactionStatus || 'N/A',
      "Wallet Txn Id": item.wallet_tnx_id || 'N/A',
      "Description/Narration": item.descriptionOrNarration || 'N/A',
      "Payer/Beneficiary Name": item.payerOrBeneficiaryName || 'N/A',
      "Payer/Beneficiary Account Number": item.payerOrBeneficiaryAccountNumber || 'N/A',
      "Payer/Beneficiary Bank": item.payerOrBeneficiaryBank || 'N/A',
      "Source Amount": formatCurrencyAmount('', item.sourceAmount, 2, 'amount') || 'N/A',
      "Source Currency": item.sourceCurrency || 'N/A',
      "Transaction Amount": formatCurrencyAmount('', item.transactionAmount, 2, 'amount') || 'N/A',
      "Wallet Amount": formatCurrencyAmount('', item.walletAmount, 2, 'amount') || 'N/A',
      "Wallet Debit/Credit": item.walletDebitOrCredit || 'N/A',
      "Rate": formatCurrencyAmount('', item.rate, 2, 'rate') || 'N/A',
      "Fee": formatCurrencyAmount('', item.fee, 2, 'amount') || 'N/A',
      "Balance Before": formatCurrencyAmount('', item.balanceBefore, 2, 'amount') || 'N/A',
      "Balance After": formatCurrencyAmount('', item.balanceAfter, 2, 'amount') || 'N/A',
      "Transaction Date": dayjs(item.transactionDate).format("DD.MM.YYYY - HH:mm") || 'N/A'
  }))
}

export const initial = { opacity: 0, x: "60%" };
export const animate = { opacity: 1, x: "0" };
export const exit = { opacity: 0, x: "60%" };

export function getCountryCode(countryName: any) {
  for (const code in countries) {
    if (countries[code].name.toLowerCase() === countryName.toLowerCase()) {
      return code;
    }
  }
  return null;
}

export const EXCLUDED_CURRENCIES = ["USD", "ZMW", "KES", "XOF", "TZS"];

export const ALLOWED_WALLET_CURRENCIES = ["NGN", "USD", "ZAR", "KES", "ZMW"];

export const CURRENCY_CODE_WITHOUT_CLASS = ["USD", "NGN"];

export const totalWalletTransaction = (
  amount: string | number | null | undefined, 
  fee: string | number | null | undefined, 
  currencyCode: string
) => {
  const extractNumber = (value: unknown): number => 
    typeof value === "number"
    ? value
    : typeof value === "string"
    ? parseFloat(value.replace(/[^\d.-]/g, "")) || 0
    : 0;

  const numericAmount = extractNumber(amount)
  const numericFee = extractNumber(fee)

  const totalAmount = numericAmount + numericFee
  const currencySymbol = COLLECTION_CURRENCIES[currencyCode].symbol || ''

  return currencySymbol + totalAmount.toFixed(2)
}