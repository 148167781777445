import React, { useState } from "react";
import TransactionStatus from "../../../../components/transactions/components/TransctionStatus";
import { DetailTitle, TotalAmount } from "../../../Transactions/style";
import {
  BeneficiaryWrapper,
  Check,
  CheckFlex,
  CopyIcon,
  Flex,
  Heading,
  Paragraph,
  TransactionWrapper,
} from "./styles";
import { totalWalletTransaction } from "../../utils";
import { useParams } from "react-router-dom";
import { truncateString } from "../../../Cards/utils";
import { Tooltip } from "antd";
import { ReactComponent as QuestionMark } from '../../../../assets/icons/questionMark.svg'

interface Props {
  reference: string;
  amount?: string;
  rate?: string;
  date: string;
  transId?: string;
  status?: string;
  sourceAmount?: string;
  destinationAmount?: string;
  transactionType?: string;
  narration?: string;
  bankName?: string;
  accountNumber?: string;
  accountName?: string;
  fee?: string;
  requestId: string;
}

const Details = ({
  reference,
  amount,
  date,
  transId,
  rate,
  narration,
  bankName,
  status,
  sourceAmount,
  destinationAmount,
  transactionType,
  accountNumber,
  accountName,
  fee,
  requestId,
}: Props) => {
  const [copy, setCopy] = useState<string | null>(null);
  const { currencyCode } = useParams()

  const onCopyLinkToClipboard = (data: string) => {
    navigator.clipboard.writeText(data);

    setCopy(data);
    setTimeout(() => {
      setCopy(null);
    }, 3000);
  };
  
  const DEBIT = transactionType === 'Debit'
  const tooltipText = "Amount Debited: after including fee"

  return (
    <>
      <DetailTitle>{totalWalletTransaction(amount, fee, currencyCode)}</DetailTitle>
      <TotalAmount>
        Total amount
        <Tooltip
          overlayClassName="input-tooltip"
          placement="right"
          title={tooltipText}
          trigger={["click"]}
        >
          <QuestionMark/>
        </Tooltip>
      </TotalAmount>
      <TransactionWrapper>
        <Flex>
          <Paragraph>Status</Paragraph>
          <TransactionStatus text={status} />
        </Flex>
        <Flex>
          <Paragraph>{DEBIT ? 'Request ID' : 'Transaction ID'}</Paragraph>
          <CheckFlex>
            {copy ? (
              <Check />
            ) : (
              <CopyIcon
                onClick={() => onCopyLinkToClipboard(DEBIT ? requestId : transId)}
                data-testid="copy-icon"
              />
            )}

            <Heading>{truncateString(DEBIT ? requestId : transId, 16)}</Heading>
          </CheckFlex>
        </Flex>

        <Flex>
          <Paragraph>Transaction type</Paragraph>
          <Heading>{transactionType}</Heading>
        </Flex>

        <Flex>
          <Paragraph>Rate</Paragraph>
          <Heading>{rate}</Heading>
        </Flex>

        <Flex>
          <Paragraph>Amount</Paragraph>
          <Heading>{sourceAmount}</Heading>
        </Flex>

        <Flex>
          <Paragraph>Fee</Paragraph>
          <Heading>{fee}</Heading>
        </Flex>

        <Flex>
          <Paragraph>Date</Paragraph>
          <Heading>{date}</Heading>
        </Flex>

        <Flex>
          <Paragraph>Narration</Paragraph>
          <Heading>{narration}</Heading>
        </Flex>
      </TransactionWrapper>

      {DEBIT && <BeneficiaryWrapper>
        <Flex>
          <Paragraph>{DEBIT ? 'Beneficiary name' : 'Payer name'}</Paragraph>
          <Heading>{accountName}</Heading>
        </Flex>

        <Flex>
          <Paragraph>{DEBIT ? 'Beneficiary account number' : 'Payer account number'}</Paragraph>
          <Heading>{accountNumber}</Heading>
        </Flex>

        <Flex>
          <Paragraph>{DEBIT ? 'Beneficiary bank' : 'Payer bank'}</Paragraph>
          <Heading>{bankName}</Heading>
        </Flex>
      </BeneficiaryWrapper>}
    </>
  );
};

export default Details;
