import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchTransactions,
  getAllFees,
  getAllTimePayoutData,
  getDefaultExchangeRate,
  getFilteredPayoutRequests,
  getPendingBalance,
  getRequestFee,
  getSettlementBalance,
  requestPayout,
  getCollectionBreakDown,
  getMerchantCollectionAccountStatement,
  getallCollectionsBreakdown,
} from "./settlementsAPI";
import { fetchStatementsForExport } from "../../utils/fetchStatementsForExport";

export interface SettlementState {
  settlements: any[];
  payoutRequests: any[];
  fees: any[];
  status: "idle" | "loading" | "failed" | "succeeded";
  isLoading: false;
  error: string | null;
  settlementBalance: number;
  pendingBalance: any;
  walletId: any;
  totalPaid: any;
  collections: any;
  availableCollectionBalance: number;
  ledgerCollectionBalance: number;
  todayCollectionBalance: number;
  collectionHoldBalance: number;
}

const initialState: SettlementState = {
  settlements: [],
  payoutRequests: [],
  status: "idle",
  isLoading: false,
  error: null,
  settlementBalance: 0,
  pendingBalance: null,
  walletId: null,
  totalPaid: null,
  fees: [],
  collections: [],
  availableCollectionBalance: 0,
  ledgerCollectionBalance: 0,
  todayCollectionBalance: 0,
  collectionHoldBalance: 0,
};

export const settlementsInfo = createAsyncThunk(
  "merchant/settlements",
  async ({ walletId }: { walletId: string | number }) =>
    fetchTransactions({ walletId }),
);

export const settlementsPendingBalance = createAsyncThunk(
  "merchant/settlements/pending/balance",
  async ({ businessId }: { businessId: string | number }) =>
    getPendingBalance({ businessId }),
);

export const settlementsPaid = createAsyncThunk(
  "merchant/settlements/total/paid",
  async ({ businessId }: { businessId: string | number }) =>
    getAllTimePayoutData({ businessId }),
);

export const settlementFees = createAsyncThunk(
  "merchant/settlements/all/fees",
  async () => getAllFees(),
);

export const settlementFeeByCountry = createAsyncThunk(
  "merchant/settlements/all/fees",
  async ({ countryCode, feeType }: { countryCode: string; feeType: string }) =>
    getRequestFee({ countryCode, feeType }),
);

export const settlementsBalance = createAsyncThunk(
  "merchant/settlements/balance",
  async ({ businessId }: { businessId: number; businessCurrency: string }) =>
    getSettlementBalance({ businessId }),
);

export const collectionBreakDown = createAsyncThunk(
  "merchant/collection/breakdown",
  async ({ businessId }: { businessId: string | number }) =>
    getCollectionBreakDown({ businessId }),
);

export const requestSettlement = createAsyncThunk(
  "merchant/settlements/request",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await requestPayout({ message: payload });
    } catch (err) {
      return rejectWithValue(err.response?.data ?? "An unknown error occurred");
    }
  },
);

export const filteredPayoutRequests = createAsyncThunk(
  "merchant/payouts/request",
  async ({ filter }: { filter: any }) => getFilteredPayoutRequests(filter),
);

export const merchantCollectionAccountStatement = fetchStatementsForExport(
  getMerchantCollectionAccountStatement,
  "merchant/collection/account/statement",
);

export const fetchCollectionsForExport = createAsyncThunk(
  "collections/fetchForExport",
  async ({
    businessId,
    startDate,
    endDate,
  }: {
    businessId: string;
    startDate: string;
    endDate: string;
  }) => {
    let allData = [];
    let currentPage = 1;
    const hasNextPage = true;

    do {
      const requestData = {
        business: businessId,
        startDay: startDate,
        endDay: endDate,
        pageNumber: currentPage,
        pageSize: 100,
      };

      const response = await getallCollectionsBreakdown(requestData);
      if (!response?.transactionSplit?.length) {
        break;
      }

      const stackedData = response.transactionSplit;
      allData = allData.concat(stackedData);

      currentPage++;
    } while (hasNextPage);

    return allData;
  },
);

export const fetchPayoutForExport = createAsyncThunk(
  "payout/fetchForExport",
  async ({
    businessId,
    startDay,
    endDay,
  }: {
    businessId: string;
    startDay: string;
    endDay: string;
  }) => {
    let allPayouts = [];
    let currentPage = 1;
    const hasNextPage = true;

    do {
      const requestData = {
        business: businessId,
        startDay: startDay,
        endDay: endDay,
        pageNumber: currentPage,
        pageSize: 100,
      };

      const response = await getFilteredPayoutRequests(requestData);

      if (!response?.length) {
        break;
      }

      allPayouts = allPayouts.concat(response);

      currentPage++;
    } while (hasNextPage);

    return allPayouts;
  },
);

export const defaultExchangeRate = createAsyncThunk(
  "rate/exchange",
  async ({
    sourceCurrency,
    amount,
    destinationCurrency,
  }: {
    sourceCurrency: string;
    amount: string | number;
    destinationCurrency: string;
  }) =>
    getDefaultExchangeRate({
      sourceCurrency,
      amount,
      destinationCurrency,
    }),
);

export const settlementsSlice = createSlice({
  name: "settlements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(settlementsInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(settlementsInfo.fulfilled, (state, action) => {
        state.status = "idle";
        state.settlements = action.payload;
      })
      .addCase(settlementsInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(settlementsPendingBalance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(settlementsPendingBalance.fulfilled, (state, action) => {
        state.status = "idle";
        state.pendingBalance = action.payload;
      })
      .addCase(settlementsBalance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(settlementsBalance.fulfilled, (state, action) => {
        state.status = "idle";
        const businessCurrency = action.meta?.arg?.businessCurrency;
        const walletBalance = action.payload.find(
          (p: { currency: string }) => p.currency === businessCurrency,
        );
        state.settlementBalance = walletBalance?.availableBalance;
        state.availableCollectionBalance =
          walletBalance?.availableCollectionBalance;
        state.ledgerCollectionBalance = walletBalance?.ledgerCollectionBalance;
        state.todayCollectionBalance = walletBalance?.todayCollectionBalance;
        state.collectionHoldBalance = walletBalance?.collectionHoldBalance;
        state.walletId = walletBalance?.id;
      })
      .addCase(requestSettlement.pending, (state) => {
        state.status = "loading";
      })
      .addCase(requestSettlement.fulfilled, (state, action) => {
        state.status = "idle";
        state.payoutRequests.unshift({
          merchantWithdrawRequest: action.payload,
        });
      })
      .addCase(requestSettlement.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(filteredPayoutRequests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(filteredPayoutRequests.fulfilled, (state, action) => {
        state.status = "idle";
        state.payoutRequests = action.payload;
      })
      .addCase(settlementsPaid.fulfilled, (state, action) => {
        state.status = "idle";
        state.totalPaid = action.payload;
      })
      .addCase(settlementFees.fulfilled, (state, action) => {
        state.status = "idle";
        state.fees = action.payload;
      })
      .addCase(collectionBreakDown.pending, (state) => {
        state.status = "loading";
      })
      .addCase(collectionBreakDown.fulfilled, (state, action) => {
        state.status = "idle";
        state.collections = action.payload;
      })
      .addCase(collectionBreakDown.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(merchantCollectionAccountStatement.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        merchantCollectionAccountStatement.fulfilled,
        (state, action) => {
          state.status = "idle";
        },
      )
      .addCase(merchantCollectionAccountStatement.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCollectionsForExport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCollectionsForExport.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(fetchCollectionsForExport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPayoutForExport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPayoutForExport.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(fetchPayoutForExport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default settlementsSlice.reducer;
