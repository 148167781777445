import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import EventFactory from "../../utils/eventFactory";

const useTrackPage = () => {
  const location = useLocation();

  useEffect(() => {
    const eventFactory = EventFactory.getInstance();
    eventFactory.trackPageView();
  }, [location]);
};

export default useTrackPage;
