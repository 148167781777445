export const fileFormatOptions = [
    { label: ".CSV file format", value: "csvFile" },
    { label: ".XLS file format", value: "xlsFile" }
];

export const transactionOptions = [
    { label: "All transactions", value: "allTransactions" },
    { label: "Collections", value: "collections" },
    { label: "Payout", value: "payout" }
];

export const transactionTypeMap = {
    payout: "Payouts",
    collections: "Collections",
    allTransactions: "All Transactions"
};
