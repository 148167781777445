import {
  ContentWrapper,
  Error,
  Heading,
  InputWrapper,
  ItemWrapper,
} from "../styles";
import { Button } from "../../../../../../components/common/Button";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import { savedCNYData } from "../../../../redux/formDataSlice";
import {ACCOUNT_ID_TYPES, AttachmentFileType, cnyTypeOfpaymentMethod} from "../../../../static";
import { Input } from "../../../../../../components/common/Input";
import { DocumentUpload } from "../../../../components/DocumentUpload";
import CustomDatePicker from "../../../../../../components/common/CustomDateRangePicker/CustomDatePicker";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  uploadCNYMerchantFile,
  fetchCNYCardBank,
} from "../../../../redux/slice";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import Loader from "../../../../../../components/common/Loader";
import InputAndSelect from "../../../../../../components/common/InputAndSelect";
import {merchantBusinessKeys} from "../../../../../Settings/settingsSlice";
import dayjs from "dayjs";
import {InputPhone} from "../../../../../../components/common/InputPhone";

type Props = {
  handleNext?: () => void;
  handlePrevious?: () => void;
};

interface UploadCNYMerchantFilePayload {
  id: number;
  purpose: string;
  attachmentType: string | undefined;
}

type Bank = {
  code: string;
  name: string;
};


type FormInputs = {
  cardNumber: string;
  cardholderFirstName: string;
  cardholderLastName: string;
  bankCode: string;
  cardBrand: string;
  logisticsCompany: string;
  logisticsOrderNumber: string;
  comercialDocuments: string;
  accountNumber: string;
  accountName: string;
  bankName?: string;
  document: string;
  productName: string;
  productNumber: string;
  tradeType: string;
  orderNumber: string;
  orderDate: string;
  totalBeneficiaryAmount: number | string;
  transactionAmount: string;
  commercialDocument: string;
  accountIdTypes: string;
  accountEmail?: string;
  accountPhoneNumber?:string;
};

type UserData = {
  banks: Bank[];
};

const TransactionDetails = ({ handleNext, handlePrevious }: Props) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormInputs>();
  const { loading } = useAppSelector((state) => state?.wallets || {});
  const { keys } = useAppSelector((state) => state.settings);
  const [userData, setUserData] = useState<UserData>(null);
  const getSavedData = useAppSelector((state) => state.formData.cnyData);
  const { businessId } = useAppSelector((state) => state.users);
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
  );
  const { paymentMethod, beneficiary } = getSavedData || {};

  const checkAccountId = watch("accountIdTypes")



  const excludedFields = cnyTypeOfpaymentMethod
    .filter((method) => ["alipay", "wechatpay"].includes(method.value))
    .map((method) => method.value)
    .includes(paymentMethod);

  const dispatch = useAppDispatch();
  const publicKey = keys?.data?.publicKey;

  useEffect(() => {
    if (businessId) {
      dispatch(merchantBusinessKeys(businessId));
    }
  }, [businessId, dispatch]);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    const formattedDate = dayjs(data.orderDate);
    const formattedDateString = formattedDate.format("YYYY-MM-DD");
    data.orderDate = formattedDateString;
    dispatch(savedCNYData(data));

    handleNext?.();
  };

  const docType = watch("commercialDocument");

  const handleFileChange = async (title?: string, file?: File | null) => {
    if (!file) return;

    const action = await dispatch(
      uploadCNYMerchantFile({
        file,
        purpose: getSavedData.paymentPurpose,
        attachmentType: docType,
        xAuthToken: publicKey,
      }),
    );

    if (uploadCNYMerchantFile.rejected.match(action)) {
      onShowErrorAlert("error", action.payload as string);
    }

    else if(uploadCNYMerchantFile.fulfilled.match(action)) {
      const { id }: UploadCNYMerchantFilePayload = action.payload

      dispatch(savedCNYData({
        commercialDocument: id
      }));

    }
  };

  const handleDataUpdate = (field: keyof UserData, value: any) => {
    setUserData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const currencyOptions = [{ label: "CNY", value: "CNY" }];


  useEffect(() => {
    const fetchBanks = async () => {
      const action = await dispatch(
        fetchCNYCardBank({ xAuthToken: publicKey, includeType: paymentMethod !== "bank_account_business" && paymentMethod !== "bank_account_individual" }),
      );

      if (fetchCNYCardBank.fulfilled.match(action)) {
        const uniqueBanks = Array.from(
          new Map(
            action.payload.data.map((bank: any) => [bank.code, bank]),
          ).values(),
        );

        handleDataUpdate("banks", uniqueBanks);
      }
    };

    fetchBanks().then((r) => r);
  }, [businessId]);

  const formatCreditCardNumber = (value: string) => {
    const numericValue = value.replace(/\D/g, "");
    const formattedValue = numericValue.replace(/(\d{4})(?=\d)/g, "$1 ");
    return formattedValue.trim();
  };

  useEffect(() => {
    if (getSavedData) {
      Object.keys(getSavedData).forEach((key) => {
        if (key === "orderDate") {
          setValue(key as keyof FormInputs, null);
        } else {
          setValue(
              key as keyof FormInputs,
              getSavedData[key as keyof FormInputs],
          );
        }
      });
    }
  }, [getSavedData, setValue]);

  return (
    <ContentWrapper>
      <Loader isLoading={loading} />
      {!excludedFields && paymentMethod && (
          <InputWrapper>
            <Controller
                name="bankCode"
                control={control}
                rules={{ required: "Bank is required" }}
                render={({ field }) => {
                  const bankOptions = useMemo(
                      () =>
                          userData?.banks?.map((bank: any) => ({
                            value: bank.code,
                            label: bank.name,
                          })),
                      [userData?.banks]
                  );

                  return (
                      <CustomSelect
                          value={field.value}
                          options={bankOptions}
                          onChange={(selectedValue) => {
                            field.onChange(selectedValue);
                            const selectedBank = userData?.banks?.find(
                                (bank: any) => bank.code === selectedValue
                            );
                            setValue("bankName", selectedBank?.name || "");
                          }}
                          $width="100%"
                          placeholder="Bank name"
                          showSearch
                          filterOption={(inputValue, option) =>
                              option.label.toLowerCase().includes(inputValue.toLowerCase())
                          }
                      />
                  );
                }}
            />
            {<Error>{errors?.bankCode?.message}</Error>}
          </InputWrapper>

      )}




      {paymentMethod === "unionpay" ? (
          <React.Fragment>


            <InputWrapper>
              <Controller
                  name="cardNumber"
                  control={control}
                  rules={{ required: "Card number is required" }}
                  render={({ field }) => (
                      <Input
                          type="text"
                          label="Card number"
                          value={field.value || ""}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const formattedValue = formatCreditCardNumber(e.target.value);
                            field.onChange(formattedValue);
                          }}
                          hasError={!!errors.cardNumber}
                      />
                  )}
              />
              {<Error>{errors?.cardNumber?.message}</Error>}
            </InputWrapper>

            <ItemWrapper>
              <InputWrapper>
                <Controller
                    name="cardholderFirstName"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                        <Input
                            type="text"
                            label="Cardholder first name"
                            value={field.value}
                            onChange={field.onChange}
                            hasError={!!errors?.cardholderFirstName}
                            labelColor="#FFFFFF"
                        />
                    )}
                />
                {errors?.cardholderFirstName?.message && (
                    <Error>{errors.cardholderFirstName.message}</Error>
                )}
              </InputWrapper>

              <InputWrapper>
                <Controller
                    name="cardholderLastName"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                        <Input
                            type="text"
                            label="Cardholder last name"
                            value={field.value}
                            onChange={field.onChange}
                            hasError={!!errors?.cardholderLastName}
                            labelColor="#FFFFFF"
                        />
                    )}
                />
                {errors?.cardholderLastName?.message && (
                    <Error>{errors.cardholderLastName.message}</Error>
                )}
              </InputWrapper>
            </ItemWrapper>
          </React.Fragment>
      ) : (
          (paymentMethod !== "wechatpay" && paymentMethod !== "alipay") && (
              <React.Fragment>
                <InputWrapper>
                  <Controller
                      name="accountName"
                      control={control}
                      rules={{ required: "Account name is required" }}
                      render={({ field }) => (
                          <Input
                              type="text"
                              label="Account name"
                              value={field.value}
                              onChange={field.onChange}
                              hasError={!!errors.accountName}
                          />
                      )}
                  />
                  {errors?.accountName?.message && <Error>{errors.accountName.message}</Error>}
                </InputWrapper>

                <InputWrapper>
                  <Controller
                      name="accountNumber"
                      control={control}
                      rules={{ required: "Account number is required" }}
                      render={({ field }) => (
                          <Input
                              type="text"
                              label="Account number"
                              value={field.value}
                              onChange={field.onChange}
                              hasError={!!errors.accountNumber}
                          />
                      )}
                  />
                  {
                      <Error>{errors?.accountNumber?.message}</Error>
                  }
                </InputWrapper>
              </React.Fragment>
          )
      )}




      {(paymentMethod === "alipay" || paymentMethod === "wechatpay") && (
          <React.Fragment>
            <InputWrapper>
              <Heading>Account ID</Heading>

              <Controller
                  name="accountIdTypes"
                  control={control}
                  rules={{ required: "Account ID Type is required" }}
                  render={({ field }) => {
                    const filteredOptions = paymentMethod === "wechatpay"
                        ? ACCOUNT_ID_TYPES.filter(option => option.value !== "EMAIL")
                        : ACCOUNT_ID_TYPES;

                    return (
                        <CustomSelect
                            value={field.value}
                            options={filteredOptions}
                            onChange={field.onChange}
                            $width="100%"
                            placeholder="Account ID types"
                        />
                    );
                  }}
              />

              {<Error>{errors?.accountIdTypes?.message}</Error>}
            </InputWrapper>

            {checkAccountId === "EMAIL" ? (
                <InputWrapper>
                  <Controller
                      name="accountEmail"
                      control={control}
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Please enter a valid email address",
                        },
                      }}
                      render={({ field }) => (
                          <Input
                              type="text"
                              label="Account email address"
                              value={field.value}
                              hasError={!!errors.accountEmail}
                              onChange={field.onChange}
                          />
                      )}
                  />
                  {<Error>{errors?.accountEmail?.message}</Error>}
                </InputWrapper>
            ): (

                <InputWrapper>
                  <Controller
                      name="accountPhoneNumber"
                      control={control}
                      rules={{ required: "Account phone number is required" }}
                      render={({ field }) => (
                          <InputPhone
                              label="Account phone number"
                              field={field}
                              defaultCountry="CN"
                          />
                      )}
                  />
                  {<Error>{errors?.accountPhoneNumber?.message}</Error>}
                </InputWrapper>

            )}


          </React.Fragment>
      )}


      {!excludedFields && beneficiary === "company" && (
        <InputWrapper>
          <Heading>Product/Service information</Heading>

          <Controller
            name="logisticsCompany"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Input
                type="text"
                label="Logistics company (optional)"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </InputWrapper>
      )}
      {!excludedFields && beneficiary === "company" && (
        <InputWrapper>
          <Controller
            name="logisticsOrderNumber"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Input
                type="text"
                label="Logistics order number (optional)"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </InputWrapper>
      )}

      {!excludedFields && beneficiary === "company" && (
        <InputWrapper>
          <Heading>Attachment file type</Heading>

          <Controller
            name="commercialDocument"
            control={control}
            rules={{ required: "Commercial document is required" }}
            render={({ field }) => (
              <CustomSelect
                value={field.value}
                options={AttachmentFileType}
                onChange={field.onChange}
                $width="100%"
                placeholder="Attachment file type"
              />
            )}
          />
          {<Error>{errors?.commercialDocument?.message}</Error>}
        </InputWrapper>
      )}

      {!excludedFields && beneficiary === "company" && (
        <InputWrapper>
          <Controller
            name="document"
            control={control}
            rules={{
              required: "Please upload a document",
              validate: (file) => file || "Uploaded file must be valid",
            }}
            render={({ field }) => (
              <DocumentUpload
                showExtra
                onFileChange={(file: File | null) => {
                  field.onChange(file);
                  handleFileChange(docType, file);
                }}
              />
            )}
          />
          {<Error>{errors?.document?.message}</Error>}
        </InputWrapper>
      )}

      {!excludedFields && beneficiary === "company" && (
        <InputWrapper>
          <Controller
            name="productName"
            control={control}
            rules={{ required: "Product name is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="Product name"
                value={field.value}
                onChange={field.onChange}
                hasError={errors.productName?.type === "required"}
              />
            )}
          />
          {<Error>{errors?.productName?.message}</Error>}
        </InputWrapper>
      )}

      {!excludedFields && beneficiary === "company" && (
        <InputWrapper>
          <Controller
            name="productNumber"
            control={control}
            rules={{ required: "Product number is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="Product number"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.productNumber}
              />
            )}
          />
          {<Error>{errors?.productNumber?.message}</Error>}
        </InputWrapper>
      )}

      {getSavedData?.paymentMethod === "bank_account_business" && (
          <InputWrapper>
            <Controller
                name="totalBeneficiaryAmount"
                control={control}
                rules={{ required: "Amount is required" }}
                render={({ field }) => (
                    <InputAndSelect
                        amountValue={field.value}
                        width="100%"
                        placeholder="Total Amount"
                        options={currencyOptions}
                        selectValue="CNY"
                        onAmountChange={field.onChange}
                    />
                )}
            />
            {errors?.totalBeneficiaryAmount && <Error>{errors?.totalBeneficiaryAmount?.message}</Error>}
          </InputWrapper>
      )}

      {!excludedFields && beneficiary === "company" && (
        <ItemWrapper>
          <InputWrapper>
            <Controller
              name="orderNumber"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field }) => (
                <Input
                  type="text"
                  label="Order number"
                  value={field.value}
                  onChange={field.onChange}
                  hasError={!!errors?.orderNumber}
                  labelColor="#FFFFFF"
                />
              )}
            />
            {<Error>{errors?.orderNumber?.message}</Error>}
          </InputWrapper>

          <InputWrapper>
            <Controller
              name="orderDate"
              control={control}
              rules={{ required: "Order date is required" }}
              render={({ field }) => (
                <CustomDatePicker
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Order date"
                />
              )}
            />
            {<Error>{errors?.orderDate?.message}</Error>}
          </InputWrapper>
        </ItemWrapper>
      )}

      <ItemWrapper>
        <Button
          onClick={handlePrevious}
          theme="alternate"
          height="48px"
          width="203px"
        >
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          label="Continue"
          height="48px"
        />
      </ItemWrapper>
    </ContentWrapper>
  );
};

export default TransactionDetails;
