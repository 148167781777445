import React from "react";
import DrawerContainer from "../../../../components/common/Drawer/Drawer";
import { TransactionDetails } from "../TransactionDetails";

interface Props {
  setState?: (data) => void;
  openDrawer?: boolean;
  data?: any;
}

const Drawer = ({ setState, openDrawer, data }: Props) => {
  const handleOpen = () => {
    setState((prev) => ({
      ...prev,
      openDrawer: false,
    }));
  };

  return (
    <DrawerContainer
      placement="right"
      title="Transaction Details"
      open={openDrawer}
      onClose={handleOpen}
    >
      <TransactionDetails
        accountName={data?.accountName}
        bankName={data?.bankName}
        accountNumber={data?.accountNumber}
        requestId={data?.requestId}
        transId={data?.transId}
        narration={data?.narration}
        date={data?.date}
        sourceAmount={data?.amount}
        destinationAmount={data?.amount}
        amount={data?.amount}
        rate={data?.rate || 'N/A'}
        reference={data?.ref}
        status={data?.status}
        transactionType={data?.transactionType}
        fee={data?.fee}
      />
    </DrawerContainer>
  );
};

export default Drawer;
