import React, { useState } from "react";
import { DatePickerContainer, DateWrapper, Label } from "./style";
import "./style.css";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { ReactComponent as PrevIcon } from "./assets/arrow left.svg";
import { ReactComponent as NextIcon } from "./assets/arrow-right.svg";
import type { Dayjs } from 'dayjs'

interface InputProps {
  value?: any;
  placeholder?: string;
  onChange?: any;
  disabledDate?: any;
  animatedLabel?: string;
  lightBorder?: boolean;
  defaultPickerValue?: Dayjs;
}

const CustomDatePicker = ({ ...props }: InputProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleFocusBlur = (isFocused: boolean) => setIsFocused(isFocused);
  
  return (
    <DatePickerContainer>
    <Label className={isFocused || props.value ? "active" : ""}>{props.animatedLabel}</Label>
    <DateWrapper
      {...props}
      $lightBorder={props.lightBorder}
      suffixIcon={<CalendarIcon />}
      dropdownClassName="picker-container"
      prevIcon={<PrevIcon />}
      nextIcon={<NextIcon />}
      superPrevIcon={null}
      superNextIcon={null}
      onFocus={() => handleFocusBlur(true)}
      onBlur={() => handleFocusBlur(false)}
      placeholder={props.animatedLabel ? '' : props.placeholder}
    />
     </DatePickerContainer>
  );
};

export default CustomDatePicker;
