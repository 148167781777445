import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Protected from "./app/router/Protected";
import { routeConfig } from "./app/router/routerConfig";
import { appRoutePaths } from "./app/router/utils";
import useDisableRightClickAndInspect from "./app/hooks/useDisableRightClickAndInspect";
import { useFeatureFlagEnabled } from "posthog-js/react";
import useTrackPage from "./app/hooks/useTrackPage";

const AppRoutes: React.FC = () => {
  useTrackPage();

  return (
      <Routes>
        {routeConfig.map(({ path, element, protected: isProtected }, index) => (
            <Route
                key={index}
                path={path}
                element={isProtected ? <Protected element={element} /> : element}
            />
        ))}
        <Route
            path="*"
            element={<Navigate to={appRoutePaths.login} replace />}
        />
      </Routes>
  );
};

const App: React.FC = () => {
  const flagEnabled = useFeatureFlagEnabled("enable_inspect");
  useDisableRightClickAndInspect(flagEnabled);

  return (
      <Router>
        <AppRoutes />
      </Router>
  );
};

export default App;
