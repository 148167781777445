import React from "react";
import { Input } from "../../../../components/common/Input";
import {
  Wrapper,
  NgnLabel,
  InputContainer,
  Inputs,
} from "../PaymentLinkSuccess/style";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../../../../components/common/Button";

interface FormInputs {
  entityName: string;
  bvn: string;
  fullName: string;
}

interface Props {
  onSubmit?: (data: FormInputs) => void;
  loading?: boolean;
  currencyCode: string;
  showBankDetail: any;
  openModal: any;
}

const NGNVirutalAccount = ({
  onSubmit,
  loading,
  showBankDetail,
  currencyCode,
  openModal,
}: Props) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormInputs>();

  const watchAll = watch();
  const subText = (detail, modal) => {
    if (detail) {
      return "Get your account details to receive funds";
    } else if (modal) {
      return "Provide your full name and BVN, this should be entered exactly as it appears on your BVN.";
    } else {
      return "Provide BVN to get details";
    }
  };

  return (
    <Wrapper>
      <h1>Get NGN bank account details</h1>

      {currencyCode === "NGN" && (
        <NgnLabel>{subText(showBankDetail, openModal)}</NgnLabel>
      )}
      <InputContainer>
        <Inputs>
          <Controller
            name="bvn"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <Input
                type="number"
                label="BVN"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.bvn?.type === "required" && (
            <span className="error">Please provide your BVN</span>
          )}

          {watchAll?.bvn &&
            watchAll?.bvn?.length !== 11 &&
            watchAll?.bvn?.length !== 0 && <span>BVN must be 11 digits</span>}
        </Inputs>
      </InputContainer>

      <Button
        theme="secondary"
        height="40px"
        fontSize="12px"
        label={loading ? "loading..." : `Request account details`}
        type="submit"
        onClick={handleSubmit((data) => {
          onSubmit(data);
        })}
        disabled={watchAll?.bvn?.length !== 11}
      />
    </Wrapper>
  );
};

export default NGNVirutalAccount;
