import TransactionStatus from "../../components/transactions/components/TransctionStatus";
import dayjs from 'dayjs';
import exportFromJSON from "export-from-json";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { formatCurrencyAmount } from "../../utils/currency";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);


export const tableColumns = [
    {
        title: "Payout ID",
        dataIndex: "payoutid",
        key: "payoutid",
    },
    {
        title: "Bank",
        dataIndex: "accBank",
        key: "accBank",
    },
    {
        title: "Account name",
        dataIndex: "accName",
        key: "accName",
    },
    {
        title: "Account number",
        dataIndex: "accNo",
        key: "accNo",
    },
    {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: "Request date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text: string) => <TransactionStatus text={text} />,
    },
];



export const allCollectionsColumn = [

    {
        title: "Reference",
        dataIndex: "ref",
        key: "ref",
    },
    {
        title: "Merchant amount",
        dataIndex: "merchant_amount",
        key: "merchant_amount",
    },
    {
        title: "Payer amount",
        dataIndex: "payer_amount",
        key: "payer_amount",
    },
    {
        title: "Amount less fees",
        dataIndex: "amount_less",
        key: "amount_less",
    },
    
    {
        title: "Settlement currency",
        dataIndex: "currency",
        key: "currency",
    },
    {
        title: "Settlement amount",
        dataIndex: "settlement_amount",
        key: "settlement_amount",
    },
   
    {
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text: string) => (
          <TransactionStatus text={text} />
        ),
      },
    

]


export const getExportCollectionsBreakDown = async (data, currencyCode) => {
    return data?.transactionSplit.map(
        (item) => ({
            key: item.transaction.id,
            ref: item?.transaction.tnxRef,
            merchant_amount:
                item.transaction.sourceCurrency +
                " " +
                item.transaction.sourceAmount,
            payer_amount:
                item.transaction.currency +
                " " +
                item.transaction.chargedAmount,
            currency:
                item.transaction.sourceCurrency || "--",
            source:
                currencyCode +
                " " +
                (
                    item.transaction.amount / 100 ||
                    "0.00"
                ).toLocaleString(),
            amount_less:
                item.foreignCurrency +
                " " +
                item.foreignAmount,
            paymentType: item.transaction.paymentType,
            settlement_amount:
                item.transaction.sourceCurrency +
                " " +
                item.transaction.settledAmount,
            amount:
                currencyCode +
                " " +
                Number(
                    item.transaction.sourceAmount,
                ).toLocaleString(),
            date: dayjs(
                item.transaction.createdAt,
            ).format("DD.MM.YYYY HH:mm"),
            settled:
                currencyCode +
                " " +
                (
                    item.transaction.settledAmount ||
                    "0.00"
                ).toLocaleString(),
            local:
                currencyCode +
                " " +
                (item.amount / 100).toLocaleString(),
            less:
                item.foreignCurrency +
                " " +
                item.foreignAmount,
            reference:
                item.transaction.tnxRef || "No Ref",
            rate:
                item.transaction.settlementRate ||
                "0.00",
        }),
    );
};

export const exportToCSV = async (downloadedData, currencyCode) => {
    const data = await getExportCollectionsBreakDown(downloadedData, currencyCode);
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({
        data,
        fileName: "Collections transaction",
        exportType,
    });
};

export const exportToXLS = async (downloadedData, currencyCode) => {
    const data = await getExportCollectionsBreakDown(downloadedData, currencyCode);
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({
        data,
        fileName: "Collections Breakdown",
        exportType,
    });
};

export const getExportPayoutData = async (exportCollectionData) => {    
    if (!Array.isArray(exportCollectionData)) {
        return [];
    }
    return exportCollectionData?.map(
        (item) => ({
            ref: 
                item.transaction.tnxRef?.toString(),
            transaction_id: item.transaction.tnxRef?.toString(),
            merchant_currency: item.transaction.sourceCurrency,
            merchant_amount: formatCurrencyAmount('', item.transaction.sourceAmount, 2, 'amount'),
            payer_currency: item.transaction.currency,
            payer_amount: formatCurrencyAmount('', item.transaction.chargedAmount, 2, 'amount'),
            rate: formatCurrencyAmount('', item.transaction.rate, 2, 'rate'),
            created_at:
                dayjs(
                    item.transaction.createdAt,
                ).format("DD.MM.YYYY - HH:mm") || "N/A",
            settlement_currency: item.transaction.settledCurrency,
            settlement_amount:
                formatCurrencyAmount('', item.transaction.settledAmount, 2, 'amount'),
            amount_less_currency: item.foreignCurrency,
            amount_less_fees:
                formatCurrencyAmount('',item.foreignAmount, 2, 'amount'),
            paymentType: item.transaction.paymentType,
        }),
    );
};

export const formatMerchantAllTransactions = async (merchantAllTransactionsData) => {
    if (!Array.isArray(merchantAllTransactionsData)) {
        return [];
    }
    return merchantAllTransactionsData?.map(
        (item: any) => ({
            "Transaction Type": item.transactionType || "N/A",
            "Payment Method": item.paymentMethod || "N/A",
            "Transaction Status": item.transactionStatus || "N/A",
            "Transaction Amount": formatCurrencyAmount('', item.transactionAmount, 2, 'amount') || "N/A",
            "Transaction Currency": item.transactionCurrency || "N/A",
            "Merchant Reference ID": item.merchantReferenceId?.toString() || "N/A",
            "Description": item.description || "N/A",
            "Local Currency": item.localCurrency || "N/A",
            "Payer/Local Amount": formatCurrencyAmount('', item.payerOrRefundAmount, 2, 'amount') || "N/A",
            "Wallet Debit/Credit": item.walletDebitOrCredit || "N/A",
            "Balance Before": formatCurrencyAmount('', item.balanceBefore, 2, 'amount') || "N/A",
            "Balance After": formatCurrencyAmount('', item.balanceAfter, 2, 'amount') || "N/A",
            "Fee Currency": item.feeCurrency || "N/A",
            "Fee Exchange Rate": formatCurrencyAmount('', item.feeExchangeRate,2, 'rate') || "N/A",
            "Transaction Fee": formatCurrencyAmount('',item.transactionFee, 2, 'amount') || "N/A",
            "Fee In Local Currency": formatCurrencyAmount('', item.feeInLocalCurrency, 2, 'amount') || "N/A",
            "Settlement Rate": formatCurrencyAmount('', item.settlementRate, 2, 'rate') || "N/A",
            "Settlement Currency": item.settlementCurrency || "N/A",
            "Settlement Amount": formatCurrencyAmount('', item.settlementAmount, 2, 'amount') || "N/A",
            "Transaction Date": dayjs(item.updatedAt).format("DD.MM.YYYY - HH:mm") || "N/A",
            "Wallet Transaction Id": item.walletTnxId?.toString() || "N/A",
        })
    )
}

const getExportData = async (payoutRequests, accountMap) => {
    if (!Array.isArray(payoutRequests)) {
        return [];
    }
    return payoutRequests?.map((settle) => ({
        "Payout ID":
            settle?.merchantWithdrawRequest.requestId?.toString(),
        Status: settle?.merchantWithdrawRequest
            ?.payoutStatus,
        Bank:
            accountMap[
                settle?.merchantWithdrawRequest
                    ?.bankAccount
            ]?.accBank ?? "N/A",
        "Account number":
            accountMap[
                settle?.merchantWithdrawRequest
                    ?.bankAccount
            ]?.accNo ?? "N/A",
        "Account name":
            accountMap[
                settle?.merchantWithdrawRequest
                    ?.bankAccount
            ]?.accName ?? "N/A",
        Date: settle?.merchantWithdrawRequest?.createdAt,
        Currency: settle?.merchantWithdrawRequest?.wallet?.currency,
        Amount: formatCurrencyAmount('', settle?.merchantWithdrawRequest?.amount, 2, 'amount'),
    }));
};

export const exportToXLSBalance = async (exportCollectionData, activeToggle, payoutRequests, accountMap) => {
    let data;
    if (activeToggle === "Payout") {
        data = await getExportData(payoutRequests, accountMap);
    } else {
        data = await getExportPayoutData(exportCollectionData);
    }

    const exportType = exportFromJSON.types.xls;
    const fileName =
        activeToggle === "Payout"
            ? "payouts"
            : "Collections";

    exportFromJSON({
        data,
        fileName,
        exportType,
    });
};

export const exportToCSVBalance = async (exportCollectionData, activeToggle, payoutRequests, accountMap) => {
    let data;
    
    if (activeToggle === "Payout") {
            data = await getExportData(payoutRequests, accountMap);
        } else {
            data = await getExportPayoutData(exportCollectionData);
        }
    
        const exportType = exportFromJSON.types.csv;
        const fileName =
            activeToggle === "Payout"
                ? "payouts"
                : "Collections";
    
        exportFromJSON({
            data,
            fileName,
            exportType,
        });
    
};