import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import {
  addAdditionalInformation,
  addBusinessContact,
  addBusinessDeclaration,
  addBusinessDirector,
  addBusinessInformation,
  addDirectorDocuments,
  addRequiredBusinessDocuments,
  addSettlementDetails,
  deleteDirector,
  getBusinessCategory,
  getBusinessType,
  getLastOnboardingSteps,
  getListOfDirectors,
  getParticularStep,
  getRequiredBusinessDocuments,
} from "./actions";
import { BusinessDocUploadState } from "../steps/BusinessDocuments";

const initialState = {
  loading: false,
  error: null,
  success: false,
  errormsg: null,
  data: {},
  businessTypeId: null,
  directorsList: null,
  uploadedBusinessDocs: [] as BusinessDocUploadState[],
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    updateBusinessType: (state: any, action: PayloadAction<number>) => {
      state.businessTypeId = action.payload;
    },
    updateDirectorsList: (state: any, action: PayloadAction<any>) => {
      state.directorsList = action.payload;
    },
    updateUploadedBusinessDocs: (
      state: any,
      action: PayloadAction<BusinessDocUploadState[]>,
    ) => {
      state.uploadedBusinessDocs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBusinessInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addBusinessInformation.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(addBusinessInformation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getBusinessType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBusinessType.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(getBusinessType.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getBusinessCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBusinessCategory.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(getBusinessCategory.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getLastOnboardingSteps.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLastOnboardingSteps.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(getLastOnboardingSteps.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(addBusinessContact.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addBusinessContact.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(addBusinessContact.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(addBusinessDirector.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addBusinessDirector.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(addBusinessDirector.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(addSettlementDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addSettlementDetails.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(addSettlementDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getRequiredBusinessDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRequiredBusinessDocuments.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(getRequiredBusinessDocuments.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(addRequiredBusinessDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addRequiredBusinessDocuments.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(addRequiredBusinessDocuments.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getListOfDirectors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getListOfDirectors.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(getListOfDirectors.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(deleteDirector.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDirector.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(deleteDirector.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(addDirectorDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addDirectorDocuments.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(addDirectorDocuments.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(addAdditionalInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAdditionalInformation.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(addAdditionalInformation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(addBusinessDeclaration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addBusinessDeclaration.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(addBusinessDeclaration.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getParticularStep.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getParticularStep.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(getParticularStep.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const {
  updateBusinessType,
  updateDirectorsList,
  updateUploadedBusinessDocs,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
