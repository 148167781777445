import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { usePostHog } from "posthog-js/react";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import {
  FormStyle,
  FormFooter,
  FormInputDiv,
  LoginDiv,
  SubtitleParagraph,
  Wrapper,
  PasswordValidationBox,
  PasswordValidationItem,
  FormsFlex,
} from "./style";
import { Input } from "../../common/Input";
import { InputPhone } from "../../common/InputPhone";
import { Button } from "../../common/Button";
import Tooltip, { Direction, Position } from "../../common/Tooltip";

import { resendActivation, signupUser } from "../../../api/authAPI";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import SignupSuccess from "../../auth/SignupSuccess";
import Loader from "../../common/Loader";
import useCountryOptions from "../../../app/hooks/useCountryList";

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  passwordConfirmation: string;
  agreement: boolean;
  legalBusinessName: string;
  countryOfRegistration: string;
};

const SignupForm = () => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const posthog = usePostHog();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [signupEmail, setSignupEmail] = useState("");
  const { onShowAlert } = useContext(NetworkErrorAlertContext);

  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [initialState, setInitialState] = useState(true);
  const [deviceWidth, setDeviceWidth] = useState(
    document.body.clientWidth || 0
  );
  const mailformat = /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

  const watchAll = watch();

  const countryIncorporation = useCountryOptions();

  const publicReferrer = new URLSearchParams(window.location.search);
  const getPublicReferrer = publicReferrer.get("r");

  const onWindowResize = () => {
    setDeviceWidth(document.body.clientWidth || 0);
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const checkPasswordLength = (password: string) => {
    if (password?.length >= 8) {
      setCharNumberValid(true);
      return true;
    }
    setCharNumberValid(false);
    return false;
  };

  const checkSpecialCharacters = (password: string) => {
    const pattern = /[!@#$%^&*()_+={};':,.<>/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
      return true;
    }
    setSpecialCharValid(false);
    return false;
  };

  const checkUppercase = (password: string) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
      return true;
    }
    setUppercaseValid(false);
    return false;
  };
  const checkLowercase = (password: string) => {
    const pattern = /[a-z]/;
    if (password && pattern.test(password)) {
      setLowercaseValid(true);
      return true;
    }
    setLowercaseValid(false);
    return false;
  };

  const checkNumber = (password: string) => {
    const pattern = /\d/;
    if (pattern.test(password)) {
      setNumberValid(true);
      return true;
    }
    setNumberValid(false);
    return false;
  };

  const onChangePassword = (val) => {
    setInitialState(false);
    checkPasswordLength(val);
    checkNumber(val);
    checkUppercase(val);
    checkLowercase(val);
    checkSpecialCharacters(val);
  };

  useEffect(() => {
    const pass = watchAll?.password;

    checkLowercase(pass);
    checkPasswordLength(pass);
    checkNumber(pass);
    checkUppercase(pass);
    checkSpecialCharacters(pass);
  }, [watchAll?.password]);

  const mutation = useMutation(signupUser, {
    onError: (error: AxiosError, variables) => {
      const errorObj = error?.response?.data;
      let errorMessage = "";

      if (errorObj.message === "Request failed with status code 409") {
        errorMessage = "The email or phone number exists. Please login";
      } else {
        errorMessage = errorObj.message;
      }
      onShowAlert(errorObj, errorMessage);
      posthog?.capture("signup_b2b", {
        status: "failure",
        failure_reason: errorMessage,
      });

      Sentry.setUser({
        email: variables.email,
        username: `${variables.firstName} ${variables.lastName}`,
      });

      Sentry.captureException(error);
      setIsLoading(false);
    },
    onSuccess: async (data, variables) => {
      posthog?.capture("signup_b2b", {
        status: "success",
      });
      setIsSubmitted(true);
      setSignupEmail(variables.email);
      setIsLoading(false);
    },
  });

  const resendEmailMutation = useMutation(resendActivation, {
    onError: (error) => {
      Sentry.captureException(error);
    },
    onSuccess: async (data, variables) => {
      window.lintrk("track", {
        conversion_id: 6482905,
      });
      setIsSubmitted(true);
      setSignupEmail(variables.email);
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsLoading(true);
    setInitialState(false);

    mutation.mutate({
      firstName: data.firstName,
      lastName: data.lastName,
      businessName: data.legalBusinessName,
      businessCountry: data.countryOfRegistration,
      email: data.email,
      username: data.phone,
      password: data.password,
      matchingPassword: data.password,
      userType: "MERCHANT",
      regUrl: window.location.origin,
      publicReferrer: getPublicReferrer,
    });
  };

  const onResendEmailClick = () => {
    resendEmailMutation.mutate({
      email: signupEmail,
      url: window.location.origin,
    });
  };

  const doesPasswordHaveError =
    !charNumberValid ||
    !numberValid ||
    !lowercaseValid ||
    !specialCharValid ||
    !uppercaseValid;

  return !isSubmitted ? (
    <Wrapper>
      <Loader isLoading={isLoading}/>
      <div>
        <SubtitleParagraph>
          Create your Klasha Business account
        </SubtitleParagraph>
        <FormStyle onSubmit={handleSubmit(onSubmit)}>
          <FormsFlex>
            <FormInputDiv>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    label="First name"
                    onChange={field.onChange}
                    hasError={errors.firstName?.type === "required"}
                    value={field.value}
                  />
                )}
              />
              {errors.firstName?.type === "required" && (
                <span>{watchAll?.firstName} First name is required</span>
              )}
            </FormInputDiv>

            <FormInputDiv>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    label="Last name"
                    onChange={field.onChange}
                    value={field.value}
                    hasError={errors.lastName?.type === "required"}
                  />
                )}
              />
              {errors.lastName?.type === "required" && (
                <span>{watchAll?.lastName} Last name is required</span>
              )}
            </FormInputDiv>
          </FormsFlex>



          <FormInputDiv
            $hasError={["required", "pattern"].indexOf(errors.email?.type) > -1}
          >
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: mailformat,
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  hasError={
                    ["required", "pattern"].indexOf(errors.email?.type) > -1
                  }
                  value={field.value}
                  onChange={field.onChange}
                  id="email"
                  label="Business e-mail address"
                />
              )}
            />
            {errors.email?.type === "required" && (
              <span>{watchAll?.email} Email is required</span>
            )}
            {errors.email?.type === "pattern" && (
              <span>Please enter a valid email address</span>
            )}
          </FormInputDiv>

          <FormInputDiv
            $hasError={["required"].indexOf(errors.phone?.type) > -1}
          >
            <Controller
              name="phone"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <InputPhone
                  height="46px"
                  label="Business phone number"
                  placeholder="Business phone number"
                  field={field}
                  hasError={["required"].indexOf(errors.phone?.type) > -1}
                />
              )}
            />
            {errors.phone?.type === "required" && (
              <span>{watchAll?.phone} Phone Number is required</span>
            )}
          </FormInputDiv>

          <FormInputDiv>
            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
                minLength: 8,
                maxLength: 20,
                pattern: /[ !@#$%^&*()_+={};':,.<>/?]/i,
              }}
              render={({ field }) => (
                <>
                  <Input
                    type="password"
                    label="Password"
                    disableLabel
                    field={field}
                    hasError={errors.password?.type === "required"}
                    onChange={() => {
                      onChangePassword(field?.value);
                    }}
                    classname={`${
                      !initialState && doesPasswordHaveError
                        ? "password_error_style"
                        : ""
                    } is-tooltip-field`}
                  />
                  <Tooltip
                    position={
                      deviceWidth >= 1440 ? Position.right : Position.top
                    }
                    direction={Direction.start}
                  >
                    <PasswordValidationBox $initialState={initialState}>
                      <ul>
                        <PasswordValidationItem hasError={!uppercaseValid}>
                          1 uppercase letter
                        </PasswordValidationItem>
                        <PasswordValidationItem hasError={!lowercaseValid}>
                          1 lowercase letter
                        </PasswordValidationItem>
                        <PasswordValidationItem hasError={!numberValid}>
                          1 number
                        </PasswordValidationItem>
                        <PasswordValidationItem hasError={!specialCharValid}>
                          1 special character
                          {`(!@#$%^&*()_+-={}[]|:”;’<>?/.,)`}
                        </PasswordValidationItem>
                        <PasswordValidationItem hasError={!charNumberValid}>
                          8+ characters
                        </PasswordValidationItem>
                      </ul>
                    </PasswordValidationBox>
                  </Tooltip>
                </>
              )}
            />
            {!initialState && doesPasswordHaveError && (
              <span>Password does not meet the minimum requirements</span>
            )}
          </FormInputDiv>
          <FormFooter>
            <Button
              label="Create account"
              type="submit"
              width="100%"
              height="40px"
              fontSize="12px"
            />
            <LoginDiv>
              <span>
                Already have an account? <Link to="/auth/login">Log in</Link>
              </span>
            </LoginDiv>
          </FormFooter>
        </FormStyle>
      </div>
    </Wrapper>
  ) : (
    <SignupSuccess onResendEmailClick={onResendEmailClick} />
  );
};

export default SignupForm;
