import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import CustomTable from "../../../../../components/common/CustomTable";
import dayjs from "dayjs";
import { ReactComponent as EmptyTeam } from "../../assets/icons/emptyTeam.svg";
import EmptyWrapper from "./EmptyWrapper";
import { StyledTransactionsTable } from "../../styles";
import InviteModal, {
    formData,
} from "./InviteModal";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../app/hooks";
import Loader from "../../../../../components/common/Loader";
import { SuccessAlertContext } from "../../../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import {
    DisableTeam,
    GetAllInvitedTeam,
    InviteTeam,
    ResendInvitation,
    UpdateTeamData,
} from "../../redux/actions";
import { Button } from "../../../../../components/common/Button";
import { formatLongString } from "../../../../../utils/formatString";
import { capitalizeName } from "../../utils/capitalizeName";
import { items, tableColumn } from "../../utils/static";
import Menu from "../Menu";
import EditModal from "./EditModal";
import EventFactory from "../../../../../utils/eventFactory";

const Team = () => {
    const tableColumns = [
        ...tableColumn,

        {
            title: "",
            dataIndex: "operation",
            key: "operation",
            render: (text, record) => (
                <Menu
                    items={items}
                    handleItemClick={(key) =>
                        handleItemClick(key, record)
                    }
                    tableData={record}
                />
            ),
        },
    ];
    const [data, setData] = useState({
        openModal: false,
        tableData: null,
        editModal: false,
        userData: null,
        apiSuccess: false,
        activeToggle: "Team members"
    });

    const { loading } = useAppSelector(
        (state) => state.multiUser || {},
    );
    const { onShowAlert: onShowSuccessAlert } =
        useContext(SuccessAlertContext) || {};
    const { onShowAlert: onShowErrorAlert } =
        useContext(NetworkErrorAlertContext) || {};
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const domain = window.location.origin;

    const eventFactory = EventFactory.getInstance()

    const dispatch = useAppDispatch();

    const handleDataUpdate = (field, value) => {
        setData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const sendInvitation = async (data: formData) => {
        const action = await dispatch(
            InviteTeam({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                appUrl: domain,
                businessId: businessId,
                roleName: data.role
            }),
        );
        if (InviteTeam.rejected.match(action)) {
            onShowErrorAlert("", String(action.payload));

            eventFactory.captureEvent("invite_team_form_submitted", {
                status: "failure",
                error_message: String(action.payload),

                submitted_data: JSON.stringify({
                    first_name: data.firstName,
                    last_name: data.lastName,
                    email: data.email,
                    phone_number: data.phoneNumber || "",
                    role: data.role || "",
                })

            })



        }
        if (InviteTeam.fulfilled.match(action)) {
            onShowSuccessAlert(
                "Successfully sent invite to team",
            );
            await handleGetAllTeamMembers();
            handleDataUpdate("openModal", false);
            handleDataUpdate("apiSuccess", true);

            eventFactory.captureEvent("invite_team_form_submitted", {
                status: "success",
                submitted_data: JSON.stringify({
                    first_name: data.firstName,
                    last_name: data.lastName,
                    email: data.email,
                    phone_number: data.phoneNumber || "",
                    role: data.role || "",
                })
            })
        }
    };

    const EditUserData = async (editData: formData) => {
        const action = await dispatch(
            UpdateTeamData({
                memberId: data.userData.member,
                phoneNumber: editData.phoneNumber,
                roleName: editData.role
            }),
        );
        if (UpdateTeamData.rejected.match(action)) {
            onShowErrorAlert("", String(action.payload));
        }
        if (UpdateTeamData.fulfilled.match(action)) {
            onShowSuccessAlert(
                "Member’s details updated successfully!",
            );
            setData((prev) => ({
                ...prev,
                editModal: false,
            }));
            await handleGetAllTeamMembers();
        }
    };

    const handleGetAllTeamMembers = async () => {
        try {
            const data = await dispatch(
                GetAllInvitedTeam({ businessId }),
            );

            if (GetAllInvitedTeam.fulfilled.match(data)) {
                const responseData = data.payload.data;

                const tableData = responseData?.members.map(
                    (data) => ({
                        firstName: data.first_name,
                        lastName: data.last_name,
                        email: formatLongString(
                            data.email,
                            "15",
                        ),
                        fullEmail: data.email,
                        phoneNumber: data.phone_number,
                        role: capitalizeName(data.role),
                        createdAt: dayjs(
                            data.created_at,
                        ).format("DD.MM.YYYY - HH:mm"),
                        status: data.status,
                        operation: handleItemClick,
                        member: data.member_id,
                    }),
                );
                handleDataUpdate("tableData", tableData);
            }
        } catch (error) {
            return error
        }
    };

    useEffect(() => {
        if (businessId && data.activeToggle === "Team members") {
            handleGetAllTeamMembers();
        }
    }, [businessId]);

    const handleItemClick = async (key, rowData) => {
        let action;

        switch (key) {
            case "1":
                handleDataUpdate("editModal", true);
                handleDataUpdate("userData", rowData);
                break;
            case "2":
                action = await dispatch(
                    ResendInvitation({
                        memberId: rowData?.member,
                        appUrl: domain,
                    }),
                );
                if (
                    ResendInvitation.rejected.match(action)
                ) {
                    onShowErrorAlert(
                        "",
                        String(action.payload),
                    );
                }
                if (
                    ResendInvitation.fulfilled.match(action)
                ) {
                    onShowSuccessAlert(
                        "Invitation re-sent successfully",
                    );
                    await handleGetAllTeamMembers();
                }

                break;
            case "3":
                action = await dispatch(
                    DisableTeam({ teamId: rowData.member }),
                );
                if (DisableTeam.rejected.match(action)) {
                    onShowErrorAlert(
                        "",
                        String(action.payload),
                    );
                }
                if (DisableTeam.fulfilled.match(action)) {
                    onShowSuccessAlert("User deactivated");
                    await handleGetAllTeamMembers();
                }

                break;
            default:
                break;
        }
    };

    

    return (
        <>
            <Loader isLoading={loading} />
            <StyledTransactionsTable>
                <div className="invite-team">
                    {!loading && data?.tableData?.length > 0 ? (
                        <Button
                            label="Invite team member"
                            height="40px"
                            width="156px"
                            onClick={() =>
                                handleDataUpdate(
                                    "openModal",
                                    true,
                                )
                            }
                        />
                    ): ''}
                </div>
                
                <CustomTable
                    columns={tableColumns}
                    icon={<EmptyTeam />}
                    extraEmptyChildren={
                        <EmptyWrapper
                            handleInviteTeam={() =>
                                handleDataUpdate(
                                    "openModal",
                                    true,
                                )
                            }
                            loading={loading}
                        />
                    }
                    dataSource={data?.tableData}
                />
                <InviteModal
                    open={data.openModal}
                    setState={setData}
                    handleInviteTeam={sendInvitation}
                    apiSuccess={data.apiSuccess}
                />

                <EditModal
                    open={data.editModal}
                    setState={setData}
                    data={data?.userData}
                    handleEditData={EditUserData}
                />
            </StyledTransactionsTable>
        </>
    );
};

export default Team;
