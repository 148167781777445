import styled, { css } from "styled-components";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg";

export const Wrapper = styled.div`
    width: 100%;
    font-family: "Inter";
`;

export const Title = styled.h1`
    font-family: "Athletics";
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
    color: #000000;
    margin-bottom: 32px;
`;

export const EmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

export const EmptyWrapperTitle = styled.h1`
    font-family: "Athletics";
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
    color: #000000;
    margin: 0;
`;

export const EmptyWrapperSubTitle = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #8f8f8f;
    margin-bottom: 24px;
`;

export const StatusTag = styled.div`
    text-transform: capitalize;
    padding: 6px 0;
    width: 135px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    & > span {
        margin-left: 10px;
    }
    & > div {
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }
    ${(props: { $status: string }) => {
        if (props.$status === "pending") {
            return css`
                //background-color: #f9fbff;
                & > div {
                    background-color: #3076ff;
                }
                & > span {
                    color: #3076ff;
                }
            `;
        } else if (props.$status === "successful") {
            return css`
                //background-color: #f4fef8;
                & > div {
                    background-color: #0eab44;
                }
                & > span {
                    color: #0eab44;
                }
            `;
        } else if (props.$status === "failed") {
            return css`
                //background-color: #fdf7f8;
                & > div {
                    background-color: #f9123b;
                }
                & > span {
                    color: #f9123b;
                }
            `;
        }
        return css`
            //background-color: #ffffff;
            & > div {
                background-color: #000000;
            }
            & > span {
                color: #000000;
            }
        `;
    }}
`;

export const TabItem = styled.div`
    display: block;

    &:not(:first-child) {
        padding: 5px 20px;
    }

    &:first-child {
        padding: 5px 20px 5px 0;
    }

    &:not(:last-child) {
        border-right: 1px solid #000000;
    }
    & > button {
    }
`;

export const TabBox = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
`;

export const TabButton = styled.button`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: ${(props: { $selected: boolean }) =>
        props.$selected ? "#EF2C5A" : "#000000"};
    padding: 0;
    background-color: transparent;
    border: none;
`;

export const MobileBody = styled.div`
    padding-top: 24px;
    padding-bottom: 32px;
    @media (min-width: 768px) {
        display: none;
    }
`;

export const BoxBody = styled.div`
    @media (min-width: 768px) {
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        overflow: hidden;
    }
`;

export const Heading = styled.h1`
    color: #000;

    /* Paragraph - Inter/P3/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
`;

export const Content = styled.div`
    text-align: center;
    h1 {
        color: var(--shades-black, #000);
        text-align: center;
        font-family: Athletics;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.4px;
    }

    p {
        color: var(--shades-black-500, #757575);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
`;

export const Btns = styled.div`
    display: flex;
    justify-content: space-betweem;
    gap: 20px;
    margin-top: 20px;

    button {
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px; /* 130% */
    }
`;

export const InputWrapper = styled.div`
    margin-top: 20px;
`;

export const TableWrapper = styled.div`
    margin-top: 40px;
    @media (min-width: 768px) {
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        overflow: hidden;
    }
`;

export const TransactionWrapper = styled.div`
    border-radius: 8px;
    background: #f9f9f9;
    padding: 24px 16px;
    margin-top: 20px;
`;

export const TransactionAmount = styled.h1`
    color: #000;
    font-family: Athletics;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
`;

export const CheckFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Paragraph = styled.p`
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #6b6b6b;
    padding-top: 15px;
`;

export const CopyIcon = styled(Copy)`
    margin-bottom: 10px;
    width: 15px;
`;

export const Check = styled(CheckIcon)`
    margin-bottom: 10px;
    width: 15px;
`;

export const DetailTitle = styled.h1`
    color: #000;
    font-family: Athletics;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
    margin-bottom: 0;
`;

export const TotalAmount = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 32px;
`

export const ButtonTitle = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px;
        width: 100%;
        padding: 10px 16px;

    }
`;

export const ModalContent = styled.div`
    text-align: center;
    h1 {
        color: var(--shades-black, #000);
        text-align: center;
        font-family: Athletics;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.4px;
    }

    p {
        color: var(--shades-black-500, #757575);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
`;

export const BtnWrapper = styled.div`
    display: flex;
    justify-content: space-betweem;
    gap: 20px;
    margin-top: 20px;

    button {
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px; /* 130% */
    }
`;

export const FormWrapper = styled.div`
    margin-top: 20px;
`;
