import React, { useEffect, useState } from "react";
import { useWindowSize } from "@reach/window-size";
import ToggleButton from "../../../../components/common/ToggleButton/ToggleButton";
import CustomTable from "../../../../components/common/CustomTable";
import { useParams } from "react-router-dom";
import {
  StyledTransactionsTable,
  ToggleContainer,
} from "../TransactionsTable/style";
import {
  capitalizeName,
  extractedString,
  formatValue,
  payIntableColumns,
  tableColumns,
} from "../../utils";
import { useAppSelector } from "../../../../app/hooks";
import dayjs from "dayjs";
import Loader from "../../../../components/common/Loader";
import Tools from "../Tools";
import { getPayoutTransactions, getPayInTransactions } from "../../api";
import { formatLongString } from "../../../../utils/formatString";
import {
  formatCurrencyAmount,
} from "../../../../utils/currency";
import {
  EmptyWrapper,
  EmptyWrapperTitle,
  EmptyWrapperSubTitle,
} from "../../../Balances/style";
import { MobileBody } from "../../../Transactions/style";
import EmptyMobileCard from "../../../../components/emptyStates/EmptyMobileCard";
import WalletCard from "../WalletCard";

import Drawer from "../Drawer/Drawer";
import CustomMobileTable from "../../../../components/common/CustomMobileTable";
import CustomModal from "../../../../components/common/CustomModal";
import ExportByFilterModal from "../../../../components/common/ExportByFilterModal";
import { formatAndNameStatementForExport } from "../../../../utils/fetchStatementsForExport";

/* Opportunity to make this a reusable component: ANOTHER OPPORTUNITY TO REVAMP THIS PAGE */
const EmptyBalanceDescription = () => {
  return (
    <EmptyWrapper>
      <EmptyWrapperTitle>No transactions yet</EmptyWrapperTitle>
      <EmptyWrapperSubTitle>
        All received or sent transactions will appear here
      </EmptyWrapperSubTitle>
    </EmptyWrapper>
  );
};

interface DateProps {
  startDate: Date;
  endDate: Date;
}

const Table = () => {
  const { width } = useWindowSize();
  const { businessId } = useAppSelector((state) => state.users);

  const { wallets } = useAppSelector((state) => state.wallets);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    openDrawer: false,
    data: null,
  });
  /* Opportunity for improvement: Will be nice to consolidate the states into one */
  const [tableData, setTableData] = useState(null);
  const [debitData, setDebitData] = useState(null);
  const [creditData, setCreditData] = useState(null);
  const [totalPayInData, setTotalPayinData] = useState(null);
  const { currencyCode } = useParams();
  const [activeToggle, setActiveToggle] = useState("Credit");
  const toggleType = ["Debit", "Credit"];
  const [select, setSelect] = useState(null);
  const [status, setStatus] = useState<number>();
  const [date, setDate] = useState<DateProps>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [openExportFilterModal, setOpenExportFilterModal] = useState<boolean>(false)

  const currentWallet = wallets?.find(
    (wallet) => wallet?.currency === currencyCode,
  );

  if (!currentWallet) {
    return <div>wallet not found</div>;
  }

  const startDate = date?.[0] && dayjs(date[0])?.format("YYYY-MM-DD");
  const endDate = date?.[1] && dayjs(date[1])?.format("YYYY-MM-DD");

  const onPageChange = (page: number, size: number) => {
    setPageNumber(page);
    setPageSize(size);
  };

  const filter = () => {
    getDebitTransaction();
  };

  const filterData = (data) => {
    return data?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  };

  const handleReset = async () => {
    setStatus(null);
    setSelect(null);
  };

  const handleToggle = (item: string) => {
    setActiveToggle(item);
  };

  const text = `${currencyCode} transactions`;

  const DEBIT_COLUMN = tableColumns;
  const getCreditTransaction = async () => {
    setLoading(true);
    try {
      const data = {
        toWallet: currentWallet?.id,
        pageNumber: pageNumber,
        pageSize: 10,
      };

      await getPayInTransactions(data).then((data) => {
        setTotalPayinData(data);
        setTableData(data);
        const payInData = data?.walletTransactions?.map((item) => ({
          key: item.walletTransaction.id,
          no: item.id,
          transId:
            formatLongString(item.walletTransaction.walletTnxId, 20) || "N/A",
          type: formatValue(item.walletTransaction.userTransactionType),
          rate: item.walletTransaction.rate || "N/A",
          amount:
            formatCurrencyAmount(
              item.walletTransaction.toWallet.currency,
              item.walletTransaction.amount,
            ) || "N/A",
          narration: extractedString(item?.walletTransaction.narration),
          fee: formatCurrencyAmount(
            currencyCode,
            item.walletTransaction.fee ?? 0,
          ),
          status: item.walletTransaction.tnxStatus || "N/a",
          date: dayjs(item.walletTransaction.createdAt || "N/A").format(
            "DD.MM.YYYY ⎮ HH:mm",
          ),
          transactionType: "Credit"
        }));

        setCreditData(filterData(payInData));

        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.error(
        "An error occurred while fetching payout transactions:",
        error,
      );
    } finally {
      setLoading(false);
    }
  };

  const getDebitTransaction = async () => {
    setLoading(true);
    try {
      const commonData = {
        startDay: startDate,
        endDay: endDate,
        payoutStatus: select || status,
        pageNumber: pageNumber,
        pageSize: 10,
      };

      let payload;

      switch (currencyCode) {
        case "NGN":
          payload = {
            ...commonData,
            walletId: currentWallet?.id,
          };
          break;

        case "USD":
          payload = {
            ...commonData,
            fromWallet: currentWallet?.id,
          };
          break;

        case currentWallet.currency:
          payload = {
            ...commonData,
            toWallet: currentWallet?.id,
          };
          break;

        default:
          payload = commonData;
          break;
      }

      const data = await getPayoutTransactions(
        payload,
        currencyCode,
        currentWallet?.id,
        businessId,
      );

      if (currencyCode === "NGN") {
        const tableData = data?.foreignBankTransferRequests?.map((item) => ({
          key: item.id,
          accountName: item.accountHolderName || "N/A",
          amount:
            formatCurrencyAmount(item?.sourceCurrency, item?.amount) ||
            "N/A",
          accountNumber: item?.accountNumber || "N/A",
          status: item?.payoutStatus || "N/A",
          fee: "N/A",
          date: dayjs(item.updatedAt).format("DD.MM.YYYY - HH:mm"),
          destinationCurrency: item?.destinationCurrency || "N/A",
          rate: item?.rate || "N/A",
          transactionType: "Debit",
          bankName: item.bankName || "N/A",
          narration: capitalizeName(item?.narration) || "N/A",
          requestId: item.requestId,
        }));
        setDebitData(filterData(tableData));
      } else if (currencyCode === "ZAR") {
        const tableData = data.map((item) => ({
          key: item.id,
          accountName: item.accountName.length > 1 ? item.accountName : "N/A",
          accountNumber: item.accountNumber || "N/A",
          bankName: item.bankName || "N/A",
          amount:
            formatCurrencyAmount(item.wallet.currency, item.amount) || "N/A",
          date: dayjs(item.createdAt).format("DD.MM.YYYY - HH:mm") || "N/A",
          fee: formatCurrencyAmount(currencyCode,item.fee),
          status: item.payoutStatus || "N/A",
          transactionType: "Debit",
          requestId: item.requestId,
          narration: capitalizeName(item?.narration) || "N/A",
        }));
        setDebitData(tableData);
      } else if (currencyCode === "USD") {
        const walletData = (data?.walletTransactions || []).map(
          ({ withdrawDetails, walletTransaction }) => {
            if (!withdrawDetails) {
              console.warn("Missing withdrawDetails for a transaction");
              return null;
            }

            return {
              key: withdrawDetails.id,
              accountName: capitalizeName(withdrawDetails.accountName) || "N/A",
              accountNumber: withdrawDetails.accountNumber || "N/A",
              status: walletTransaction.tnxStatus || "N/A",
              amount:
                formatCurrencyAmount(currencyCode, walletTransaction.amount) ||
                "N/A",
              date: dayjs(walletTransaction.createdAt).format(
                "DD.MM.YYYY - HH:mm",
              ),

              fee:
                formatCurrencyAmount(currencyCode, walletTransaction.fee) ||
                "N/A",
              transactionType: "Debit",
              narration: capitalizeName(walletTransaction.narration) || "N/A",
              requestId: walletTransaction.walletTnxId
            };
          },
        );

        const formattedData = walletData.filter((item) => item !== null);
        setDebitData(filterData(formattedData));
      } else {
        const payOutData = (data || []).map((item) => ({
          key: item.id,
          beneficiary: item.accountName || "N/A",
          amount:
            formatCurrencyAmount(item.wallet.currency, item.amount) || "N/A",
          accountNumber: item.accountNumber || "N/A",
          accountName: item.accountName || "N/A",
          bankName: item.bankName || "N/A",
          status: item.payoutStatus || "N/A",
          date: dayjs(item.createdAt).format("DD.MM.YYYY - HH:mm"),
          fee: formatCurrencyAmount(currencyCode, item.fee) || 0,
          transactionType: 'Debit',
          narration: capitalizeName(item?.narration) || "N/A",
          requestId: item.requestId,
        }));
        setDebitData(filterData(payOutData));
      }

      setTableData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(
        "An error occurred while fetching payout transactions:",
        error,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeToggle === "Credit") {
      getCreditTransaction().then((r) => r);
    }
    if (activeToggle === "Debit") {
      getDebitTransaction().then((r) => r);
    }
  }, [
    currencyCode,
    pageNumber,
    startDate,
    endDate,
    select,
    status,
    search,
    activeToggle,
  ]);
  const handleDrawer = (row) => {
    setState((prev) => ({
      ...prev,
      openDrawer: true,
      data: row,
    }));
  };

  const handleCloseExportByFilterModal = () => {
    setOpenExportFilterModal(!openExportFilterModal);
  };

  const handleExportByFilters = (payload) => {
    formatAndNameStatementForExport(payload, 'wallet')
  }

  return (
    <StyledTransactionsTable width={width}>
      <Loader isLoading={loading} />
      <h3>{width > 768 ? text : ""}</h3>

      {
        <React.Fragment>
          <Tools
            search={search}
            setSearch={setSearch}
            onStatusChange={(data) => setStatus(data)}
            onDateChange={(date) => setDate(date)}
            onFilterButtonClick={filter}
            onResetButtonClick={handleReset}
            onSelectChange={(data) => setSelect(data)}
            handleExportClick={setOpenExportFilterModal}
          />

          <CustomModal
            isModalVisible={openExportFilterModal}
            onClose={handleCloseExportByFilterModal}
            width="464px"
            noPadding={true}
          >
            <ExportByFilterModal 
              exportByFilterFunction={handleExportByFilters} 
              location="wallet" 
              modalTitle={`Get ${currentWallet?.currency} Wallet Statement`}
              modalSubTitle={`Download your ${currentWallet?.currency} wallet statement from the past 3 months.`}
              id={currentWallet?.id}
              exportFileName={`${currentWallet?.currency}_wallet`}
            />
          </CustomModal>

          <ToggleContainer>
            <ToggleButton
              toggleType={toggleType}
              activeToggle={activeToggle}
              setActiveToggle={setActiveToggle}
              handleToggle={handleToggle}
            />
          </ToggleContainer>
        </React.Fragment>
      }

      {activeToggle === "Debit" && (
        <>
          {width > 768 ? (
            <CustomTable
              extraEmptyChildren={<EmptyBalanceDescription />}
              columns={DEBIT_COLUMN}
              dataSource={debitData}
              currentPage={pageNumber}
              pageSize={10}
              totalItemCount={tableData?.totalCount || 0}
              onPageChange={(page) => {
                setPageNumber(page);
              }}
              handleRowClick={handleDrawer}
            />
          ) : (
            <MobileBody>
              <CustomMobileTable
                data={debitData}
                WalletCard={WalletCard}
                EmptyMobileCard={EmptyMobileCard}
                currentPage={tableData?.pageNumber}
                totalItemCount={tableData?.totalCount}
                onPageChange={onPageChange}
                pageSize={pageSize}
                pageSizeOptions={[10, 20, 50]}
              />
            </MobileBody>
          )}
        </>
      )}

      {activeToggle === "Credit" && (
        <>
          {width > 768 ? (
            <CustomTable
              extraEmptyChildren={<EmptyBalanceDescription />}
              columns={payIntableColumns}
              dataSource={creditData}
              currentPage={pageNumber}
              totalItemCount={totalPayInData?.totalCount}
              onPageChange={onPageChange}
              handleRowClick={handleDrawer}
            />
          ) : (
            <MobileBody>
              <CustomMobileTable
                data={creditData}
                WalletCard={WalletCard}
                EmptyMobileCard={EmptyMobileCard}
                currentPage={pageNumber}
                totalItemCount={totalPayInData?.totalCount}
                onPageChange={onPageChange}
                pageSize={pageSize}
                pageSizeOptions={[10, 20, 50]}
              />
            </MobileBody>
          )}
        </>
      )}

      <Drawer  openDrawer={state.openDrawer} setState={setState} data={state.data} />
    </StyledTransactionsTable>
  );
};

export default Table;
