import api from "../../app/api";
import axios from "axios";

const defaultApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
  },
});

defaultApi.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    if (token && request.headers) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => Promise.reject(error),
);

export default api;

const api2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api2.interceptors.request.use(
  (request) => {
    const settings = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).settings,
    );

    const token = settings.keys.publicKey;
    if (token && request.headers) {
      request.headers["x-auth-token"] = `${token}`;
    }
    return request;
  },
  (error) => Promise.reject(error),
);

export const getAllWallets = async ({ businessId }) => {
  const response = await api.get(`/wallet/business/${businessId}/wallets`);
  return response?.data?.data;
};

export const getAllVirtualAccounts = async ({ businessId }) => {
  const response = await api.get(
    `/wallet/foreign/virtual/get/business/all/account/${businessId}`,
  );
  return response.data;
};

export const getAllPayouts = async (data) => {
  const response = await api.post(
    `wallet/admin/transaction/paginated/filter`,
    data,
  );
  return response.data;
};

export const getCountriesToSendFunds = async () => {
  const response = await api.get(`/merchant/send/get/all/countries`);
  return response;
};

export const createNewWallet = async ({ businessId, currency, userId }) => {
  const response = await api.post(`wallet/activate/business/wallet/`, {
    businessId,
    currencyCode: currency,
    userId,
  });
  return response;
};

export const getWalletTransactions = async (data) => {
  const response = await api.post(
    "wallet/admin/transaction/paginated/filter",
    data,
  );
  return response.data;
};

export const getWalletStatement = async ({
  id,
  startDate,
  endDate,
  pageNumber,
}) => {
  const response = await api.get(
    `/wallet/wallet/statement/${id}/${startDate}/${endDate}?page=${pageNumber}`,
  );
  return response.data;
};

export const requestForAccountDetails = async (data) => {
  // eslint-disable-next-line no-useless-catch
  try {
    api.defaults.headers.common["x-auth-token"] =
      process.env.REACT_APP_REQUEST_ACCOUNT_TOKEN;

    const response = await api.post(
      "/wallet/virtual/v2/wallet/account/business/create",
      data,
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

export const getAllVirtualAccountDetails = async (businessId) => {
  try {
    const response = await api.get(
      `/wallet/foreign/virtual/get/business/all/account/${businessId}`,
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data?.error;
  }
};

export const getAllBankAccount = async ({ currency }) => {
  try {
    const response = await api.get(
      `/wallet/merchant/bank/transfer/request/banks/${currency}`,
    );
    return response.data;
  } catch (error) {
    throw error.response.error;
  }
};

export const getBankAccountDetail = async ({
  accountNumber,
  bankCode,
  countryCode,
}) => {
  try {
    const response = await api.post(
      `/wallet/merchant/bank/transfer/request/resolve/account`,
      {
        accountNumber,
        bankCode,
        countryCode,
      },
      {
        headers: {
          "x-auth-token":
            "LS3+pE4F6a5Uqyzj3R+847a15Vyf0CyDsRRg9nWIT04a+KpzPks4ZEU1GV2ImUIL",
        },
      },
    );
    return response.data.data;
  } catch (error) {
    throw error.response.error;
  }
};
export const resolveZarAccount = async (data) => {
  const response = await api.post(
    `/wallet/merchant/bank/transfer/request/resolve/account`,

    data,
  );
  return response.data;
};

export const getTransferFee = async ({ country }) => {
  try {
    const response = await api.get(
      `/wallet/fees/get/${country}/merchantBankTransferFee`,
    );

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getInternationalTransferFee = async ({
  destinationCountry,
  sourceCountry,
}) => {
  try {
    const response = await api.get(
      `/wallet/fees/get/v2/${destinationCountry}/merchantBankTransferFee/${sourceCountry}`,
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPayInTransactions = async (data) => {
  try {
    const response = await api.post(
      `/wallet/admin/transaction/paginated/filter`,
      data,
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPayoutTransactions = async (
  data,
  currency,
  walletId,
  businessId,
) => {
  try {
    let apiUrl;
    let response;

    if (currency === "NGN") {
      apiUrl = `/wallet/foreign/banktransfer/request/admin/transaction/paginated/filter`;
      response = await api.post(apiUrl, data);
    } else if (
      currency === "ZAR" ||
      currency === "KES" ||
      currency === "ZMW" ||
      currency === "TZS"
    ) {
      apiUrl = `/wallet/merchant/bank/transfer/request/${walletId}/mine`;
      response = await api.get(apiUrl);
    } else if (currency === "USD") {
      apiUrl = `/wallet/admin/transaction/paginated/filter`;
      response = await api.post(apiUrl, data);
    } else {
      apiUrl = `/wallet/admin/transaction/paginated/filter`;
      response = await api.post(apiUrl, data);
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching payout transactions:", error);
    return {
      success: false,
      message: "An error occurred while fetching payout transactions.",
      error: error.message,
    };
  }
};

export const addFunds = async (data) => {
  try {
    const response = await api.post(
      `/wallet/funding/business/wallet/request/v2`,
      data,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllRequests = async (data) => {
  try {
    const response = await api.post(
      `/wallet/funding/business/wallet/v2/filter/paginated`,
      data,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateRequest = async (data) => {
  try {
    const response = await api.put(
      `/wallet/funding/business/wallet/update/payment/status/${data}`,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const sendFromWallet = async (data, token: string) => {
  try {
    api.defaults.headers.common["x-auth-token"] = token;
    const response = await api.post(
      `/wallet/merchant/bank/transfer/request/v2`,
      data,
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export async function getRate(payload) {
  const { data } = await defaultApi.post("/nucleus/general/exchange", payload);

  return data.data;
}

export const fetchCNYCardBank = async ({
  xAuthToken,
}: {
  xAuthToken: string;
}) => {
  try {
    const response = await api.get("/pay/payout/CNY/banks?type=bank_card", {
      headers: {
        "x-auth-token": xAuthToken,
      },
    });
    return response.data;
  } catch (error: any) {
    return (
      error.response?.data?.message ||
      "An error occurred while fetching the bank list."
    );
  }
};
