import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import StepAmount from "./components/StepAmount";
import StepBeneficiary from "./components/StepBeneficiary/StepBeneficiary";
import StepReview from "./components/StepReview";
import {
  CurrentStep,
  StepContent,
  Title,
  Wrapper,
  Stepper,
  BackButton,
} from "./style";
import { ReactComponent as BackIcon } from "../../../../assets/icons/arrow-right.svg";
import { useParams } from "react-router-dom";
import { getTransferFee, getInternationalTransferFee } from "../../api";
import { formatNumber } from "../../../../utils/currency";
import { getExchangeRate } from "../../../KlashaWire/wiresAPI";
import Loader from "../../../../components/common/Loader";
import { removeLastCharacter } from "../../utils";

interface Props {
  close: boolean;
  setClose: Dispatch<SetStateAction<boolean>>;
}

const SendFunds = ({ close, setClose }: Props) => {
  const selectedCurrency = [];
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const { currencyCode } = useParams();
  const [rate, setRate] = useState<number>();
  const [accountType, setAccountType] = useState(null);
  const [identity, setidentity] = useState(null);
  const [activeCurrency, setActiveCurrency] = useState("Same currency");
  const [internationalFee, setInternationalFee] = useState<any>("");
   const TZS = currencyCode === "TZS"

  // form 1
  const [destinationCurrency, setDestinationCurrency] = useState<string>(
    selectedCurrency?.length ? selectedCurrency[0] : []
  );
  const [beneficiaryReceives, setBeneficiaryReceives] = useState<number>();
  const [amount, setAmount] = useState<number>();
  const [sendSameCurrency, setSendSameCurrency] = useState<number>();
  const [sameCurrencyReceive, setSameCurrencyReceive] = useState();
  const [transferFee, setTransferFee] = useState(null);
  const currencyTab = currencyCode;

  // form 2
  const [internationalTransfer, setInternationalTransfer] = useState(null);
  const [localBankInfo, setLocalBankInfo] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [accountName, setAccountName] = useState<string>();
  const [paymentScheme, setPaymentScheme] = useState<string>();

  const countryName = removeLastCharacter(
    currencyCode === "ZAR" ? "SAS" : currencyCode
  );

  useEffect(() => {
    async function fetchtransactionFeeData() {
      try {
        await getTransferFee({ country: countryName }).then((data) => {
          setTransferFee(data);
        });
      } catch (error) {
        return error;
      }
    }
    if (activeCurrency === "Same currency") {
      fetchtransactionFeeData();
    }
  }, [activeCurrency]);

  // We can use regex later to remove last letter when the list gets longer. For now it's just these
  const updateCurrency = (data) => {
    if (data === "USD") {
      return "US";
    }
    if (data === "GBP") {
      return "GB";
    }
    if (data === "EUR") {
      return "EU";
    }
  };

  useEffect(() => {
    async function fetchtransactionFeeData() {
      try {
        await getInternationalTransferFee({
          destinationCountry: updateCurrency(destinationCurrency),
          sourceCountry: currencyCode,
        }).then((data) => {
          setInternationalFee(data);
        });
      } catch (error) {
        return error;
      }
    }

    if (activeCurrency === "International" && destinationCurrency !== null) {
      fetchtransactionFeeData();
    }
  }, [destinationCurrency, currencyCode, activeCurrency]);

  const InternationalMerchantFee =
    beneficiaryReceives * (Number(internationalFee?.fees) / 100) +
    internationalFee?.cap * rate;

  const getExchangeOne = async () => {
    try {
      setLoading(true);
      const data = await getExchangeRate(
        currencyTab,
        sendSameCurrency,
        currencyCode
      );

      setSameCurrencyReceive(data.amount);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getExchangeTwo = async () => {
    try {
      setLoading(true);
      const data = await getExchangeRate(
        destinationCurrency,
        amount,
        currencyCode
      );

      setBeneficiaryReceives(data.amount);
      setRate(data.rate);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (destinationCurrency && amount >= 100 && currencyCode) {
      getExchangeTwo();
    }
  }, [
    destinationCurrency,
    amount,
    currencyCode,
    sendSameCurrency,
    currencyTab,
  ]);

  useEffect(() => {
    if (
      (currencyTab && sendSameCurrency >= 50) ||
      (currencyCode === "KES" && sendSameCurrency >= 50 && currencyCode)
    ) {
      getExchangeOne();
    }
  }, [currencyCode, sendSameCurrency, currencyTab, sameCurrencyReceive]);

  const getRate = beneficiaryReceives / rate;

  const rateAmount = getRate || "";

  useEffect(() => {
    if (!sendSameCurrency) {
      setSameCurrencyReceive(null);
      setBeneficiaryReceives(null);
    }
  }, [sendSameCurrency]);

  useEffect(() => {
    if (activeCurrency === "Same currency") {
      setAmount(null);
      setDestinationCurrency(null);
    }

    if (activeCurrency === "International") {
      setSendSameCurrency(null);
    }
  }, [activeCurrency]);

  const InternationalTransferAmount =
    activeCurrency === "International"
      ? destinationCurrency + " " + formatNumber(amount)
      : "";

  const TotalInternationalTransferAmount =
    activeCurrency === "International"
      ? (beneficiaryReceives + InternationalMerchantFee).toLocaleString(
          undefined,
          {
            style: "currency",
            currency: currencyCode,
          }
        )
      : "";

  const LocalBankTransferAmount =
    activeCurrency !== "International"
      ? +sendSameCurrency?.toLocaleString(undefined, {
          style: "currency",
          currency: currencyCode || "NGN",
        })
      : "";

  const TotalLocalBankAmount =
    activeCurrency !== "International"
      ? (Number(sendSameCurrency) + Number(TZS ? 0 : transferFee?.fees)).toLocaleString(
          undefined,
          {
            style: "currency",
            currency: currencyCode || "NGN",
          }
        )
      : "";

  const AccountNumber =
    localBankInfo?.accountNumber || internationalTransfer?.accountNumber;
  const Beneficiary = accountName || internationalTransfer?.accountName;

  const steps = [
    {
      title: "Amount",
      header: "How much do you want to send?",
      content: (
        <StepAmount
          amount={amount}
          transferFee={transferFee}
          destinationCurrency={destinationCurrency}
          onNext={() => next()}
          fundsFromSameCurrency={sendSameCurrency}
          setFundsFromSameCurrency={setSendSameCurrency}
          currencyTab={currencyTab}
          sameCurrencyReceive={sameCurrencyReceive}
          transactionrate={rateAmount}
          activeCurrency={activeCurrency}
          setActiveCurrency={setActiveCurrency}
        />
      ),
    },
    {
      title: "Beneficiary",
      header: "Enter beneficiary bank details",
      content: (
        <StepBeneficiary
          identity={identity}
          setidentity={setidentity}
          accountType={accountType}
          setAccountType={setAccountType}
          activeCurrency={activeCurrency}
          onNext={() => next()}
          basicInfo={internationalTransfer}
          setBasicInfo={setInternationalTransfer}
          localBankInfo={localBankInfo}
          setLocalBankInfo={setLocalBankInfo}
          setBankName={setBankName}
          accountName={accountName}
          setAccountName={setAccountName}
          setSelectedPaymentScheme={setPaymentScheme}
        />
      ),
    },
    {
      title: "Review",
      header: "Review the details of your transfer",
      content: (
        <StepReview
          phoneNumber={localBankInfo?.phoneNumber}
          recipientAddress={localBankInfo?.recipientAddress}
          onGoBack={setCurrent}
          receipientAmount={
            InternationalTransferAmount || LocalBankTransferAmount
          }
          totalAmount={TotalInternationalTransferAmount || TotalLocalBankAmount}
          description={localBankInfo?.description}
          beneficiary={Beneficiary || localBankInfo?.zarAccountName}
          exchangeRate={
            activeCurrency === "Same currency"
              ? transferFee?.fees
              : InternationalMerchantFee.toFixed(2)
          }
          accountNumber={
            AccountNumber ||
            localBankInfo?.phone ||
            localBankInfo?.zarAccountNumber
          }
          currency={destinationCurrency}
          amount={beneficiaryReceives || sendSameCurrency}
          bankCode={localBankInfo?.bankCode}
          bankName={bankName || internationalTransfer?.sortCode}
          close={close}
          setClose={setClose}
          activeCurrency={activeCurrency}
          internationalAccountDetails={internationalTransfer}
          scheme={paymentScheme}
          bic={localBankInfo?.bic}
        />
      ),
      icon: "",
    },
  ];

  const next = async () => {
    if (current < steps.length - 1) setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <Wrapper>
      <Loader isLoading={loading} />
      <Stepper current={current} items={items} />

      <div>
        {current > 0 && (
          <BackButton onClick={() => prev()}>
            <BackIcon />
          </BackButton>
        )}
        <StepContent>
          <Title>{steps[current].header}</Title>
          <CurrentStep>{steps[current].content}</CurrentStep>
        </StepContent>
      </div>
    </Wrapper>
  );
};

export default SendFunds;
